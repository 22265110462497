import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const CustomTextFieldStyles = styled(TextField)({
    borderRadius: '7px',
    '& label': {
        color: '#0E234099',
    },
    '& label.Mui-focused': {
        color: '#F49F6C',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#F49F6C',
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#f5f5f5',

        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            borderColor: '#F49F6C',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#F49F6C',
        },
    },
});
