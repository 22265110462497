import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UIControlContextProvider } from './context/ui-control-context';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme/theme';
import { BrowserRouter } from 'react-router-dom';
import { DataProcessingContextProvider } from './context/data-processing-context';
import { SharedDataContextProvider } from './context/shared-data-context';
import { Translation } from './types/shared';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    //<React.StrictMode>
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <BrowserRouter>
                    <UIControlContextProvider
                        alertSeverity={undefined}
                        alertMessage={undefined}
                        setAlertMessage={() => 'Success'}
                        errorMessage={undefined}
                        setErrorMessage={() => 'Success'}
                        openToast={undefined}
                        setOpenToast={() => false}
                        handleCloseToast={() => {
                            false;
                        }}
                        openBackdrop={undefined}
                        setOpenBackdrop={() => false}
                        handleOpenBackdrop={() => {
                            false;
                        }}
                        handleCloseBackdrop={() => {
                            false;
                        }}
                        handleLogOut={() => {
                            false;
                        }}
                        handleNavigateToProviderList={() => {
                            false;
                        }}
                        groupNameUnique={undefined}
                        reportDetailId={undefined}
                        isDialogOpen={undefined}
                        setIsDialogOpen={() => {
                            false;
                        }}
                        handleOpenDialog={() => {
                            false;
                        }}
                        handleCloseDialog={() => {
                            false;
                        }}
                        handleManageToast={() => {
                            false;
                        }}
                        loading={undefined}
                        setLoading={() => false}
                        refetchData={undefined}
                        setRefetchData={() => false}
                    >
                        <DataProcessingContextProvider
                            transformInUnixValue={() => {
                                false;
                            }}
                            selected={undefined}
                            setSelected={() => {
                                false;
                            }}
                            handleGetIdAndType={() => {
                                false;
                            }}
                            groupId={undefined}
                            typeId={null}
                            groupType={undefined}
                            handleChipStatus={(status = 'Ready') => {
                                return { label: status, color: 'info' };
                            }}
                        >
                            <SharedDataContextProvider
                                newId={''}
                                peerGroupData={[]}
                                setPeerGroupData={() => {
                                    false;
                                }}
                                peerGroupNames={[]}
                                setPeerGroupNames={() => {
                                    false;
                                }}
                                afbStatusData={[]}
                                setAfbStatusData={() => {
                                    false;
                                }}
                                afbStatusNames={[]}
                                setAfbStatusNames={() => {
                                    false;
                                }}
                                handleTransformBooleanValue={(
                                    value: boolean
                                ) => {
                                    if (value) return 'Yes';
                                }}
                                handleTransformToBooleanValue={(
                                    value: string
                                ) => {
                                    if (value) return true;
                                }}
                                handleGetLanguageIdFromSelect={(
                                    language: string
                                ) => {
                                    if (language === 'English') return 1;
                                }}
                                handleGetLanguageItem={(
                                    translations: Translation[],
                                    languageId: number
                                ) => {
                                    return translations.find(
                                        (item) =>
                                            item.language_Id === languageId
                                    );
                                }}
                                handleGetPeerGroup={() => {
                                    false;
                                }}
                                handleGetAfbStatusGroup={() => {
                                    false;
                                }}
                                baseUrl=''
                                handleCreateEntity
                                handleCreateFund
                                handleGetLanguages={() => {
                                    false;
                                }}
                                languagesData={[]}
                            >
                                <App />
                            </SharedDataContextProvider>
                        </DataProcessingContextProvider>
                    </UIControlContextProvider>
                </BrowserRouter>
            </LocalizationProvider>
        </ThemeProvider>
    //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
