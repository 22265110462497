export const columns = [
    { field: 'name', headerName: 'Name' },
    {
        field: 'productType',
        headerName: 'Product Type',
    },
    {
        field: 'action',
        headerName: 'Action',
    },
];
