export const columns = [
    {
        field: 'informationEffectiveDate',
        headerName: 'Information effective date',
    },
    { field: 'name', headerName: 'Name' },
    { field: 'authors', headerName: 'Authors' },

    {
        field: 'file',
        headerName: 'File'
    },

    {
        field: 'delete',
        headerName: 'Delete',
    },
];
