export class HttpError extends Error {
    response: {
        status: number;
        data?: any;
    };

    constructor(status: number, message: string, data?: any) {
        super(message);
        this.name = "HttpError";
        this.response = { status, data };
    }
}
