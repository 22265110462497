import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { IFormInputs } from '../../types/shared';
import {useMemo} from "react";

interface Props {
    control: Control<IFormInputs>;
    name: string;
    data: (string | { id: string; name: string })[];
    label: boolean;
    labelId?: string;
    selectId?: string;
    labelName?: string;
    error?: boolean;
    required?: boolean;
    defaultValue?: string;
}
const OptionSelect = ({
    control,
    name,
    data,
    label,
    labelId,
    selectId,
    labelName,
    error,
    required,
    defaultValue
}: Props) => {
    const sortedData = useMemo(() => {
        return [...data].sort((a, b) => {
            if (typeof a === 'string') return a ? 1 : -1;
            if (typeof b === 'string') return b ? -1 : 1;
            return a.id ? 1 : -1;
        });
    }, [data]);
    return (
        <FormControl fullWidth>
            {label && <InputLabel id={labelId}>{labelName}</InputLabel>}
            <Controller
                name={
                    name as
                        | 'type'
                        | 'format'
                        | 'status'
                        | 'year'
                        | 'month'
                        | 'quarter'
                        | 'assetManagerDSLinkedToTheReport'
                        | 'entityType'
                }
                control={control}
                defaultValue={defaultValue}
                rules={{ required: required }}
                render={({ field }) => (
                    <Select
                        labelId={labelId}
                        label={labelName}
                        id={selectId}
                        {...field}
                        error={error}
                    >
                        {sortedData.map((item, index) => (
                            <MenuItem key={index} value={typeof item === 'string' ? item : item.id}>
                                <Tooltip title={typeof item === 'string' ? item : item.name} placement='bottom'>
                                    <span>{typeof item === 'string' ? item : item.name}</span>
                                </Tooltip>
                            </MenuItem>
                        ))}
                    </Select>
                )}
            />
        </FormControl>
    );
};

export default OptionSelect;
