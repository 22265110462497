import React, {useCallback} from 'react';
import { Data, IFormInputs } from '../../../../types/shared';
import { Control } from 'react-hook-form';
import { Box, Divider, Grid, Typography } from '@mui/material';
import OptionSelect from '../../../../components/option-select/option-select';
import TextInput from '../../../../components/text-input/text-input';
import DatePickerInfo from '../../../../components/date-picker-info/date-picker-info';
import Toggle from '../../../../components/toggle/toggle';
import { scoreCardOption } from '../../../../data/shared/data-for-button-group';
import ButtonGroup from '../../../../components/button-group/button-group';
import CustomTextarea from '../../../../components/custom-textarea/custom-textarea';
import CustomButton from "../../../../components/custom-button/custom-button";
import {useNavigate} from "react-router-dom";

interface SelectData {
    control: Control<IFormInputs>;
    name: string;
    data: (string | { id: string; name: string })[];
    labelId: string;
    label: boolean;
    selectId: string;
    labelName: string;
}

interface Props {
    control: Control<IFormInputs>;
    selectData: SelectData[];
    textInputData: Data[];
    datePickerData: Data[];
    toggleData: Data[];
    textareaData: Data[];
    researchId: undefined | string;
}

function GeneralInformation({
    control,
    selectData,
    textInputData,
    datePickerData,
    toggleData,
    textareaData,
    researchId
}: Props) {
    const navigate = useNavigate();
    const handleNavigateToReviews = useCallback(
        (
            fundId: string
        ) => {
            navigate(`/dqr/research-list/${fundId}/reviews`);
        },
        [navigate]
    );

    return (
        <Box component={'article'} sx={{ width: '100%' }}>
            <Grid container gap={2} marginTop={'20px'}>
                {textInputData.map(({ control, name, labelName }) => (
                    <Grid item key={name} width={'250px'}>
                        <TextInput
                            name={name}
                            control={control}
                            labelName={labelName}
                            disabledInput={'benchmark'}
                        />
                    </Grid>
                ))}

                <>
                    {selectData?.map(
                        ({
                            control,
                            name,
                            data,
                            labelId,
                            label,
                            selectId,
                            labelName,
                        }) => {
                            const sortedData = [...data].sort();
                            return (
                                <Grid item key={name} width={'250px'}>
                                    <OptionSelect
                                        control={control}
                                        name={name}
                                        data={sortedData}
                                        labelId={labelId}
                                        label={label}
                                        selectId={selectId}
                                        labelName={labelName}
                                    />
                                </Grid>
                            );
                        }
                    )}
                </>

                {datePickerData.map(({ control, name, labelName }) => (
                    <Grid item key={name} width={'250px'}>
                        <DatePickerInfo
                            key={name}
                            name={name}
                            control={control}
                            labelName={labelName}
                        />
                    </Grid>
                ))}
            </Grid>

            <Grid container alignItems={'center'} gap={2}>
                <Grid item>
                    <Typography margin={'22px 0 5px 0'}>
                        The fund belongs to Insight list:
                    </Typography>
                    <Grid item xs={12} display="flex" justifyContent="center">
                        <ButtonGroup
                            control={control}
                            name='belongsToInsightList'
                            data={scoreCardOption}
                        />
                    </Grid>
                </Grid>

                {toggleData.map(({ control, name, labelName }) => (
                    <Grid item key={name}>
                        <Toggle
                            name={name}
                            control={control}
                            labelName={labelName}
                        />
                    </Grid>
                ))}
                <CustomButton
                    color='warning'
                    variant='contained'
                    size='small'
                    text={'Reviews'}
                    onClick={() => {
                        handleNavigateToReviews(researchId ? researchId : '')
                    }}
                />
            </Grid>

            <Divider sx={{ margin: '20px 0' }} />

            {textareaData.map(
                ({ control, name, labelName, updateDate, updateName }) => (
                    <Grid container item marginBottom={'20px'} key={name}>
                        <CustomTextarea
                            name={name}
                            control={control}
                            labelName={labelName}
                            updateDate={updateDate}
                            updateName={updateName}
                        />
                    </Grid>
                )
            )}
        </Box>
    );
}

export default GeneralInformation;
