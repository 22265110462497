import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { AlertColor, DialogContentText } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';

interface Props {
    handleCloseDialog?: () => void;
    handleRedirect?: () => void;
    redirectButton?: boolean;
    isDialogOpen?: boolean;
    dialogTitle?: string;
    alertMessage?: string;
    alertSeverity?: AlertColor;
    yesButton?: boolean;
    handleAction?: (id: string) => void;
    selected?: GridRowSelectionModel;
}

export default function ResponsiveDialog({
    handleCloseDialog,
    handleRedirect,
    redirectButton,
    isDialogOpen,
    dialogTitle,
    alertMessage,
    yesButton,
    handleAction,
    selected,
}: Props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            fullScreen={fullScreen}
            open={isDialogOpen as boolean}
            onClose={handleCloseDialog}
            aria-labelledby='responsive-dialog-title'
            fullWidth
        >
            <DialogTitle id={dialogTitle}>{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{alertMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {yesButton && (
                    <Button
                        onClick={() =>
                            handleAction &&
                            handleAction(
                                selected ? (selected[0] as string) : ''
                            )
                        }
                    >
                        Yes
                    </Button>
                )}
                <Button onClick={handleCloseDialog}>Cancel</Button>
                {redirectButton && (
                    <Button onClick={handleRedirect}>Login</Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
