import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import CustomTable from '../../../../components/custom-table/custom-table';
import { ISIN } from '../../../../types/research-detail';
import InfoPage from '../../../info/info-page';
import dayjs from 'dayjs';
import { columns } from '../../../../data/columns/isins';
import CustomButton from '../../../../components/custom-button/custom-button';
import TextInput from '../../../../components/text-input/text-input';
import { Control } from 'react-hook-form';
import { IFormInputs } from '../../../../types/shared';
import CustomDialog from '../../../../components/custom-dialog/custom-dialog';
import { GridRowSelectionModel } from '@mui/x-data-grid';

interface Props {
    data: ISIN[];
    searchValue?: string;
    handleOpenDialog: () => void | undefined;
    control: Control<IFormInputs>;
    handleDeleteIsin: (id: string, fundId: string) => void;
    selected: GridRowSelectionModel;
    setSelected: React.Dispatch<
        React.SetStateAction<GridRowSelectionModel | []>
    >;
}

function Isins({
    data,
    handleOpenDialog,
    searchValue,
    control,
    handleDeleteIsin,
    selected,
    setSelected,
}: Props) {
    const [isinfilteredArray, setIsinFilteredArray] = useState<ISIN[]>([]);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] =
        useState<boolean>(false);

    const handleOpenDeleteDialog = useCallback(() => {
        setIsDeleteDialogOpen(true);
    }, [setIsDeleteDialogOpen]);

    const handleCloseDeleteDialog = useCallback(() => {
        setIsDeleteDialogOpen(false);
    }, [setIsDeleteDialogOpen]);

    const rows = useMemo(() => {
        return isinfilteredArray?.map(
            ({ createdDate, isin, authorName, id }) => {
                return {
                    id: id,
                    isin: isin,
                    createdDate: createdDate
                        ? dayjs(createdDate).format('DD/MM/YYYY')
                        : 'No date available',
                    author: authorName,
                };
            }
        );
    }, [isinfilteredArray]);

    const actionsData = useMemo(
        () => [
            {
                status: 'active',
                color: 'warning',
                buttonText: 'erase',
                actionType: 'isinEraseAction',
                actionEvent: (): void => handleOpenDeleteDialog(),
            },
        ],
        [handleOpenDeleteDialog]
    );

    const handleFilterIsin = useCallback(() => {
        if (!searchValue) {
            setIsinFilteredArray(data as ISIN[]);
            return;
        }
        const searchValueLower = searchValue?.toLowerCase().replace(/\s/g, '');

        const filtered = data?.filter((file) => {
            const originalNameLower = file.isin
                .toLowerCase()
                .replace(/\s/g, '');
            const authorNameLower = file.authorName
                .toLowerCase()
                .replace(/\s/g, '');

            return (
                originalNameLower.includes(searchValueLower as string) ||
                authorNameLower.includes(searchValueLower as string)
            );
        });

        setIsinFilteredArray(filtered as ISIN[]);
    }, [data, searchValue, setIsinFilteredArray]);

    useEffect(() => {
        handleFilterIsin();
    }, [handleFilterIsin]);

    useEffect(() => {
        setSelected([]);
    }, [setSelected]);

    return (
        <Grid container gap={2} flexDirection='column'>
            <Grid container gap={2} marginTop={'20px'}>
                <Grid item>
                    <CustomButton
                        type='button'
                        color='warning'
                        variant='contained'
                        size='large'
                        onClick={handleOpenDialog}
                        text={'New ISIN'}
                    />
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                    <TextInput
                        name='searchValue'
                        control={control}
                        labelName={'Search by isin or author '}
                    />
                </Grid>
            </Grid>
            {isinfilteredArray.length > 0 ? (
                <>
                    <Grid item container gap={2}>
                        {isinfilteredArray && isinfilteredArray.length > 0 && (
                            <>
                                <Grid
                                    item
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    <CustomTable
                                        useCheckbox={false}
                                        selected={selected}
                                        setSelected={setSelected}
                                        columns={columns}
                                        rows={rows}
                                        actionsData={actionsData}
                                        isSelectable
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </>
            ) : (
                <InfoPage message={'The are no ISINS to show'} />
            )}

            <CustomDialog
                open={isDeleteDialogOpen as boolean}
                title='Erase Isin'
                text='Are you sure you want to erase it?'
                onClose={handleCloseDeleteDialog}
                actions={
                    <>
                        <CustomButton
                            onClick={handleCloseDeleteDialog}
                            text='CANCEL'
                        />
                        <CustomButton
                            onClick={() =>
                                handleDeleteIsin &&
                                handleDeleteIsin(
                                    selected ? (selected[0] as string) : '', isinfilteredArray[0].fund_Id
                                )
                            }
                            text='yes'
                        />
                    </>
                }
            >
            </CustomDialog>
        </Grid>
    );
}

export default Isins;
