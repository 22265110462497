export const columns = [
    { field: 'id', headerName: 'ID' },
    {
        field: 'name',
        headerName: 'Name',
    },
    {
        field: 'contact',
        headerName: 'Contact',
    },
];
