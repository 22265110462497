import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

interface Props {
    message: string;
    minHeight?: string;
}

function InfoPage({ message, minHeight = '100vh'  }: Props) {
    return (
        <Box
            sx={{
                minHeight: minHeight,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}
        >
            <Typography>
                {message}
            </Typography>
        </Box>
    );
}

export default InfoPage;
