import { Box, CircularProgress } from '@mui/material';

function Loading() {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                width: '100%',
            }}
        >
            <CircularProgress color='primary' />
        </Box>
    );
}

export default Loading;
