import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Data } from '../../types/shared';

function CheckboxComponent({ control, name, labelName, checkboxSize = 24 }: Data) {
    return (
        <FormControlLabel
            control={
                <Controller
                    name={name}
                    control={control}
                    // rules={{ required: true }}
                    render={({ field }) => {
                        return (
                            <Checkbox
                                {...field}
                                checked={field.value as boolean}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: checkboxSize } }}
                            />
                        );
                    }}
                />
            }
            label={labelName}
        />
    );
}

export default CheckboxComponent;
