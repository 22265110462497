import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { IFormInputs } from '../../types/shared';

export interface Props {
    control: Control<IFormInputs>;
    name: string;
    labelName?: string;
    data: string[];
}

type Name =
    | 'independentRisk'
    | 'oecdRegulator'
    | 'independentAdmin'
    | 'independentCustodian'
    | 'thirdPartyStability'
    | 'governanceStability'
    | 'bigFourAuditor'
    | 'esgPolicy'
    | 'donations'
    | 'unpriSignatory'
    | 'dueDiligence'
    | 'showDueDiligence'
    | 'belongsToInsightList'
    | 'monthly';

function ButtonGroup({ control, name, data }: Props) {
    return (
        <Controller
            name={name as Name}
            control={control}
            // rules={{ required: true }}
            render={({ field }) => {
                return (
                    <ToggleButtonGroup
                        color='primary'
                        exclusive
                        aria-label='Platform'
                        {...field}
                    >
                        {data.map((d) => (
                            <ToggleButton value={d} key={d} size='large'>
                                {d}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                );
            }}
        />
    );
}

export default ButtonGroup;
