import {Box, CircularProgress, Grid} from "@mui/material";
import { useParams } from 'react-router-dom';
import CustomButton from "../../../components/custom-button/custom-button";
import Loading from "../../loading/loading";
import CustomTable from "../../../components/custom-table/custom-table";
import {columns} from "../../../data/columns/research-list/reviews-columns";
import InfoPage from "../../info/info-page";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useStore} from "../../../store";
import {useUIControlContext} from "../../../context/ui-control-context";
import axios from "axios";
import {useSharedDataContext} from "../../../context/shared-data-context";
import {FundDocuments} from "../../../types/research-list";
import dayjs from "dayjs";
import {useDataProcessingContext} from "../../../context/data-processing-context";
import ReturnButton from "../../../components/return-button/return-button";
import {margin} from "@mui/system";
import IconButton from "@mui/material/IconButton";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DeleteIcon from '@mui/icons-material/Delete';
import CustomDialog from '../../../components/custom-dialog/custom-dialog';

function ResearchReviews() {
    const accessToken = useStore((state) => state.accessToken);
    const [researchReviewData, setResearchReviewData] = useState<FundDocuments[]>([]);
    const [initialLoading, setInitialLoading] = useState(true);
    const { selected, setSelected } = useDataProcessingContext();
    const { loading, setLoading, isDialogOpen, handleOpenDialog, handleCloseDialog, handleManageToast } = useUIControlContext();
    const { baseUrl, afbStatusData, handleGetAfbStatusGroup   } = useSharedDataContext();
    const { researchId } = useParams();
    const [fileToDelete, setFileToDelete] = useState<string | null>(null);
    const [fileIndex, setFileIndex] = useState<number>(0);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const url = `${baseUrl}Fund/${researchId}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            setResearchReviewData(response.data.data.fund.reviews || []);
            handleGetAfbStatusGroup();
            setInitialLoading(false);
        } catch (error) {
            console.error('Error research list request:', error);
            handleManageToast('error', 'There was an error trying to search for the fund');
            setInitialLoading(false);
        } finally {
            setLoading(false);
        }
    }, [researchId, accessToken, baseUrl, setLoading, setResearchReviewData, handleManageToast, setInitialLoading]);

    useEffect(() => {
        fetchData();
        if (!afbStatusData || afbStatusData.length === 0) {
            handleGetAfbStatusGroup();
        }
    }, [fetchData, handleGetAfbStatusGroup]);

    const handleDelete = async (idUploadFile: string | null, index: number) => {
        try {
            setLoading(true);
            setInitialLoading(true);
            const url = `${baseUrl}Fund/UploadFile/${idUploadFile}`;
            const response = await axios.delete(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (response.status === 202) {
                setResearchReviewData(currentData => {
                    const newData = [...currentData];
                    const updatedReview = {
                        ...newData[index],
                        reviewComment: newData[index].reviewComment + " - File deleted now",
                        fileUrl: null
                    };
                    newData[index] = updatedReview;
                    return newData;
                });
                console.log('Document deleted');
            }
        } catch (error) {
            console.error('Error:', error);
            handleManageToast('error', 'Error');
        } finally {
            setLoading(false);
            setInitialLoading(false);
            handleCloseDialog();
        }
    };

    const handleOpenDeleteDialog = (id: string | null, index: number) => {
        setFileToDelete(id);
        setFileIndex(index);
        handleOpenDialog();
    };

    const rows = useMemo(() => {
        const sortedData = researchReviewData.sort((a, b) => {
            return dayjs(b.createdDate).unix() - dayjs(a.createdDate).unix();
        });

        return sortedData.map(({ reviewComment, isInsight, status_Id, authorName, createdDate, fileUrl, idUploadFile }, index) => ({
            reviewComment,
            isInsight: isInsight != undefined ? isInsight?.toString() : 'No changes',
            afbStatus: status_Id === undefined ? "No Changes" :
                status_Id == 0 ? "Empty" :
                    afbStatusData.find(status => status.id === status_Id)?.name || "No Changes",
            authorName,
            createdDate: createdDate ? dayjs(createdDate).format('YYYY-MM-DD') : undefined,
            document: fileUrl ? (
                <IconButton
                    onClick={() => {
                        if (fileUrl && fileUrl.startsWith('http')) {
                            window.open(fileUrl, '_blank');
                        }
                    }}
                >
                    <PictureAsPdfIcon />
                </IconButton>
            ) : "None",
            delete: fileUrl ? (
                <IconButton
                    onClick={() => handleOpenDeleteDialog(idUploadFile, index)}
                >
                    <DeleteIcon />
                </IconButton>
            ) : "None"
        }));
    }, [researchReviewData, afbStatusData]);

    return (
        <>
            <Box component={isDialogOpen ? 'section' : 'form'} sx={{ width: '100%' }}>
                <Grid container gap={2} flexDirection='column'>
                    <Grid item>
                        <ReturnButton />
                    </Grid>
                    {initialLoading ? (
                        <Loading />
                    ) : researchReviewData.length > 0 ? (
                        <CustomTable useCheckbox={false} selected={selected} setSelected={setSelected} columns={columns} rows={rows} />
                    ) : (
                        <InfoPage message="There are no reviews to show for this Fund, search for one" />
                    )}
                </Grid>

                <CustomDialog
                    open={isDialogOpen as boolean}
                    title='Erase file'
                    text='Are you sure you want to delete this document?'
                    onClose={handleCloseDialog}
                    actions={
                        loading ? (
                            <></>
                        ) : (
                            <>
                                <CustomButton
                                    onClick={handleCloseDialog}
                                    text='CANCEL'
                                />
                                <CustomButton
                                    onClick={() =>
                                        handleDelete(fileToDelete, fileIndex)
                                    }
                                    text='yes'
                                />
                            </>
                        )
                    }
                >
                    {loading ? (
                        <Box display='flex' justifyContent='center'>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <></>
                    )}
                </CustomDialog>
            </Box>
        </>
    );
}

export default ResearchReviews;