export const columns = [
    {
        field: 'createdDate',
        headerName: 'Created Date',
    },
    {
        field: 'isin',
        headerName: 'ISIN',
    },
    {
        field: 'author',
        headerName: 'Author ',
    },
    {
        field: 'action',
        headerName: 'Action',
    },
];
