import React from 'react';
import { Control } from 'react-hook-form';
import { IFormInputs } from '../../../../types/shared';
import { Grid } from '@mui/material';
import StarsRating from '../../../../components/stars-rating/stars-rating';
import CustomTextarea from '../../../../components/custom-textarea/custom-textarea';

interface Props {
    control: Control<IFormInputs>;
    teamLastUpdate?: string;
    teamLastUpdateName?: string;
}

function Team({ control, teamLastUpdate, teamLastUpdateName }: Props) {
    return (
        <Grid container flexDirection={'column'} gap={2} marginTop={'20px'}>
            <Grid item>
                <StarsRating control={control} name='teamStars' />
            </Grid>
            <Grid item>
                <CustomTextarea
                    name='team'
                    control={control}
                    labelName='Team'
                    updateDate={teamLastUpdate}
                    updateName={teamLastUpdateName}
                />
            </Grid>
        </Grid>
    );
}

export default Team;
