// criteria.ts
const criteria = [
    {
        key: "adultEntertainment",
        exclusion: "Adult Entertainment",
        definition: "Exclusion of companies that publish or wholesale pornographic magazines or newspapers and/or produce adult entertainment services; max. 10% revenue threshold"
    },
    {
        key: "alcohol",
        exclusion: "Alcohol",
        definition: "Exclusion of companies that produce alcoholic beverages; max. 10% revenue threshold"
    },
    {
        key: "antiPersonnelMines",
        exclusion: "Anti-Personnel Mines",
        definition: "Exclusion of companies that derive revenues from anti-personnel mines; 0% revenue threshold"
    },
    {
        key: "biologicalWeapons",
        exclusion: "Biological Weapons",
        definition: "Exclusion of companies that derive revenues from biological weapons; 0% revenue threshold"
    },
    {
        key: "chemicalWeapons",
        exclusion: "Chemical Weapons",
        definition: "Exclusion of companies that derive revenues from chemical weapons; 0% revenue threshold"
    },
    {
        key: "civilianFirearms",
        exclusion: "Civilian Firearms",
        definition: "Exclusion of companies that derive revenues from manufacturing or selling civilian firearms and/or small arms ammunitions for civilian markets; max. 10% revenue threshold"
    },
    {
        key: "clusterMunitions",
        exclusion: "Cluster Munitions",
        definition: "Exclusion of companies that derive revenues from cluster munitions; 0% revenue threshold"
    },
    {
        key: "controversialCountries",
        exclusion: "Controversial Countries",
        definition: "Exclusion of sovereign debt issuers that score particularly poorly on ESG criteria (e.g. human rights, political rights, corruption)"
    },
    {
        key: "conventionalWeapons",
        exclusion: "Conventional Weapons",
        definition: "Exclusion of companies that derive revenues from conventional weapons and/or weapon systems; max. 10% revenue threshold"
    },
    {
        key: "cropProtectionChemicals",
        exclusion: "Crop Protection Chemicals",
        definition: "Exclusion of companies that produce crop protection chemicals; max. 10% revenue threshold"
    },
    {
        key: "depletedUraniumWeapons",
        exclusion: "Depleted Uranium Weapons",
        definition: "Exclusion of companies that derive revenues from depleted uranium weapons; 0% revenue threshold"
    },
    {
        key: "gambling",
        exclusion: "Gambling",
        definition: "Exclusion of companies that are involved in gambling (e.g. betting shops, licensed bingo halls, casinos, gaming clubs, provision of on-line betting services); max. 10% revenue threshold"
    },
    {
        key: "gmo",
        exclusion: "GMO (Genetically Modified Organism)",
        definition: "Exclusion of companies that derive revenues from the development and/or production of genetically modified plants, crops, seeds, animals and microorganisms intended for agricultural use; max. 10% revenue threshold"
    },
    {
        key: "nuclearEnergy",
        exclusion: "Nuclear Energy",
        definition: "Exclusion of companies that derive revenues from nuclear energy activities; max. 30% revenue threshold"
    },
    {
        key: "nuclearWeapons",
        exclusion: "Nuclear Weapons",
        definition: "Exclusion of companies that derive revenues from nuclear weapons; 0% revenue threshold"
    },
    {
        key: "oilGasConventional",
        exclusion: "Oil & Gas (conventional)",
        definition: "Exclusion of companies that derive revenues from conventional oil & gas exploration and production; max. 30% revenue threshold"
    },
    {
        key: "oilGasUnconventional",
        exclusion: "Oil & Gas (unconventional)",
        definition: "Exclusion of companies that derive revenues from unconventional oil & gas exploration and production (e.g. oil sands, shale gas, shale oil); max. 30% revenue threshold"
    },
    {
        key: "thermalCoal",
        exclusion: "Thermal Coal",
        definition: "Exclusion of companies that derive revenues from thermal coal extraction or from thermal coal-based power generation; max. 30% revenue threshold"
    },
    {
        key: "tobacco",
        exclusion: "Tobacco",
        definition: "Exclusion of companies that produce tobacco and/or tobacco products; max. 10% revenue threshold"
    },
    {
        key: "violationsUnGlobalCompact",
        exclusion: "Violations of UN Global Compact",
        definition: "Exclusion of companies that breach the UN Global Compact principles: companies are evaluated against the ten principles covering human rights, labour, environment and anti-corruption"
    }
];

export default criteria;
