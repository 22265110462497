import React from 'react';
import { Button, ButtonProps } from '@mui/material';

interface CustomButtonProps extends ButtonProps {
    text: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
    text,
    color,
    variant,
    size,
    onClick,
    type,
    style
}) => {
    return (
        <Button
            type={type}
            color={color}
            variant={variant}
            size={size}
            style={style}
            onClick={onClick}
        >
            {text}
        </Button>
    );
};

export default CustomButton;
