export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const generateYearsFrom2019 = () => {
    const startYear = 2019;
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
        years.push({ name: year.toString() });
    }

    return years;
};

export const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
