import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

interface BnpStoreSate {
    accessToken: string | null;
    setAccessToken: (t: string | null) => void;
    azureToken: string | null;
    setAzureToken: (t: string | null) => void;
    bnpUserRol: string | null;
    setBnpUserRol: (t: string | null) => void;
    dqrUserRol: string | null;
    setDqrUserRol: (t: string | null) => void;
    concentrateReportRol: string | null;
    setConcentrateReportRol: (t: string | null) => void;
    groupId: string | undefined;
    setGroupId: (t: string | undefined) => void;
    groupType: string | undefined;
    setGroupType: (t: string | undefined) => void;
    typeId: number | null;
    setTypeId: (t: number | undefined) => void;
    groupNameUnique: string | undefined;
    setGroupNameUnique: (t: string | undefined) => void;
}

export const useStore = create<BnpStoreSate>()(
    devtools(
        persist(
            (set) => ({
                accessToken: null,
                setAccessToken: (t) => set(() => ({ accessToken: t })),
                azureToken: null,
                setAzureToken: (t) => set(() => ({ azureToken: t })),
                bnpUserRol: null,
                setBnpUserRol: (t) => set(() => ({ bnpUserRol: t })),
                dqrUserRol: null,
                setDqrUserRol: (t) => set(() => ({ dqrUserRol: t })),
                concentrateReportRol: null,
                setConcentrateReportRol: (t) => set(() => ({ concentrateReportRol: t })),
                groupId: undefined,
                setGroupId: (t) => set(() => ({ groupId: t })),
                groupType: undefined,
                setGroupType: (t) => set(() => ({ groupType: t })),
                typeId: null,
                setTypeId: (t) => set(() => ({ typeId: t })),
                groupNameUnique: undefined,
                setGroupNameUnique: (t) => set(() => ({ groupNameUnique: t })),
            }),
            {
                name: 'storage',
                storage: createJSONStorage(() => localStorage),
            }
        )
    )
);
