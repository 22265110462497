export const generateByData = ['BillingGroup', 'FundGroup'];

export const formats = ['OLD', 'STANDARD'];

export const statusData = [
    'Ready',
    'Sent',
    'Pending To Revision',
    'No results',
];
