import { Typography } from '@mui/material';
import React from 'react';

interface Props {
    date?: string;
    name?: string;
}

function FooterNote({ date, name }: Props) {
    return (
        <Typography>{`Last updated at ${date ? date : 'none'} perfomed by ${
            name ? name : 'none'
        }`}</Typography>
    );
}

export default FooterNote;
