import React, { useCallback, useRef, useEffect, useMemo, useState } from 'react';
import TabComponent from '../../../components/tab/tab';
import { useDataProcessingContext } from '../../../context/data-processing-context';
import { useParams } from 'react-router-dom';
import {Box, Typography} from '@mui/material';
import { Button, Divider, Grid } from '@mui/material';
import GeneralInformation from './general-information/general-information';
import Segmentation from './segmentation/segmentation';
import FileUpload from './file-upload/file-upload';
import ScoreCard from './score-card/score-card';
import {
    IFormInputs,
    Translation,
    ValueWithLastModification,
} from '../../../types/shared';
import { SubmitHandler, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import {
    ClientSegmentationValues,
    ManagerData,
    ManagerDataEntry,
    ProductSegmentationValue,
    ProductSegmentationValues,
    SegmentationButtonGroup,
    TexareaValue,
    UploadFileData,
} from '../../../types/risk-detail';
import OptionSelect from '../../../components/option-select/option-select';
import { languagesSelectData } from '../../../data/risk-detail/language-select-data';
import { HttpError } from '../../../errors/HttpError';
import axios from 'axios';
import { IndependentRiskValue } from '../../../enum/Dqr/independent-risk-value';
import { useUIControlContext } from '../../../context/ui-control-context';
import Loading from '../../loading/loading';
import Toast from '../../../components/toast/toast';
import SimpleBackdrop from '../../../components/backdrop/backdrop';
import { useStore } from '../../../store';
import { useSharedDataContext } from '../../../context/shared-data-context';

const RiskDetail = () => {
    const { riskId } = useParams();
    const { baseUrl } = useSharedDataContext();

    const actionAfterSubmitRef = useRef<('Save' | 'Generate') | null>(null);

    const [accessToken] = useStore((state) => [state.accessToken]);

    const { selected, setSelected } = useDataProcessingContext();
    const {
        handleTransformBooleanValue,
        handleGetLanguageIdFromSelect,
        handleGetLanguageItem,
        handleTransformToBooleanValue,
        newId,
        handleGetLanguages,
        languagesData,
    } = useSharedDataContext();

    const {
        loading,
        setLoading,
        alertSeverity,
        alertMessage,
        openToast,
        handleCloseToast,
        openBackdrop,
        handleOpenBackdrop,
        handleCloseBackdrop,
        handleManageToast,
        handleCloseDialog,
        handleLogOut,
        refetchData,
        setRefetchData,
        isDialogOpen,
        handleOpenDialog,
    } = useUIControlContext();
    const [managerData, setManagerData] = useState<ManagerData>();

    const [uploadFilesData, setUploadFilesData] = useState<UploadFileData[]>(
        []
    );
    const [managerDataArray, setManagerDataArray] = useState<
        Array<ManagerDataEntry>
    >([]);
    const [loadingData, setLoadingData] = useState(false);

    const [dataForYAxisAUM, setDataForYAxisAUM] = useState<number[]>([]);
    const [dataForYAxisAUMYears, setDataForYAxisAUMYears] = useState<string[]>(
        []
    );

    const { handleSubmit, control, watch, setValue } = useForm<IFormInputs>({
        defaultValues: {
            companyInceptionDate: dayjs(),
            structuralDescription: '',
            amhOverviewHighlights: '',
            allfunds_Id: '',
            productSegmentationDate: dayjs(),
            MoneyMarket: '',
            FixedIncome: '',
            Balance: '',
            Alternatives: '',
            Equity: '',
            FundsOfFunds: '',
            othersProductSegmentation: '',
            totalProductSegmentation: '',
            Retail: '',
            Institutional: '',
            Captive: '',
            othersClientSegmentation: '',
            totalClientSegmentation: '',
            yOne: '0',
            yTwo: '0',
            yThree: '0',
            yFour: '0',
            yFive: '0',
            assetManagerDSLinkedToTheReport: '',
            sentToConnect: undefined,
            aumConcentration: '',
            name: '',
            auditor: '',
            stability: '',
            administrator: '',
            oecdRegulator: '',
            independentAdmin: '',
            independentCustodian: '',
            thirdPartyStability: '',
            governanceStability: '',
            bigFourAuditor: '',
            esgPolicy: '',
            donations: '',
            unpriSignatory: '',
            uploadedFiles: [],
            dateOfUploadFile: dayjs(),
            dueDiligence: 'Yes',
            searchByDateOfUploadedFile: dayjs(),
            languages: 'English',
            searchValue: '',
        },
    });

    const uploadedValues = watch('uploadedFiles');

    const handleDeleteUploadedFile = useCallback(
        async (id: string) => {
            if (id && selected.length === 1) {
                const apiUrl = `${baseUrl}Manager/UploadFile/${id}`;
                setLoading(true);
                try {
                    const response = await axios.delete(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });

                    if (response) {
                        setRefetchData((prev) => !prev);
                        handleManageToast(
                            'success',
                            ' File deleted succesfully'
                        );
                    }
                } catch (error) {
                    const typedError = error as {
                        response?: { status?: number };
                    };
                    if (typedError.response?.status === 401) {
                        handleLogOut();
                    } else {
                        handleManageToast(
                            'error',
                            ' There was an error trying to erase the file'
                        );
                    }
                } finally {
                    setLoading(false);
                    handleCloseDialog();
                }
            } else {
                handleManageToast(
                    'error',
                    'You can only erase one file, please select one'
                );
            }
        },
        [
            selected,
            baseUrl,
            handleCloseDialog,
            handleManageToast,
            accessToken,
            handleLogOut,
            setLoading,
            setRefetchData,
        ]
    );

    const handleUploadFile = useCallback(
        async (file: File) => {
            const apiUrl = `${baseUrl}Manager/${riskId}/UploadFile`;

            const formData = new FormData();
            formData.append('file', file);

            handleOpenBackdrop();
            setLoadingData(true);

            try {
                const response = await axios.post(apiUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setValue('uploadedFiles', []);
                handleManageToast('success', 'Upload successful');
                setRefetchData((prev) => !prev);
                console.log('response handleUpload data', response);
            } catch (error) {
                const typedError = error as { response?: { status?: number } };

                if (typedError.response?.status === 401) {
                    handleLogOut();
                } else {
                    handleManageToast(
                        'error',
                        'There was an unexpected error trying to upload the file'
                    );
                }
            } finally {
                handleCloseBackdrop();
                setLoadingData(false);
            }
        },
        [
            setValue,
            accessToken,
            handleOpenBackdrop,
            handleManageToast,
            handleCloseBackdrop,
            setRefetchData,
            baseUrl,
            riskId,
            handleLogOut,
        ]
    );

    const fetchManagerData = useCallback(
        async (id: string) => {
            setLoading(true);
            try {
                const response = await axios.get(`${baseUrl}Manager/${id}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const aumValue = response.data.data.managerModification.aum?.value ?? "0";
                let aumArray;

                aumArray = JSON.parse(aumValue);
                if (!Array.isArray(aumArray) || aumArray.length === 0) {
                    aumArray = [["Y-5", "0"], ["Y-4", "0"], ["Y-3", "0"], ["Y-2", "0"], ["Y-1", "0"]];
                }
                if (response.data && response.data.data && response.data.data.managerModification) {
                    if (!response.data.data.managerModification.aum) {
                        response.data.data.managerModification.aum = { value: JSON.stringify([["Y-5", "0"], ["Y-4", "0"], ["Y-3", "0"], ["Y-2", "0"], ["Y-1", "0"]]) };
                    } else {
                        response.data.data.managerModification.aum.value = JSON.stringify(aumArray);
                    }
                }
                setManagerData(response.data.data.managerModification);
                setUploadFilesData(response.data.data.manager.managerUploadFile);
            } catch (error) {
                const typedError = error as { response?: { status?: number } };
                if (typedError.response?.status === 401) {
                    handleLogOut();
                }else{
                    handleManageToast(
                        'error',
                        'Unexpected error'
                    );
                }
                console.log(error);
            } finally {
                setLoading(false);
            }
        },
        [
            accessToken,
            setManagerData,
            setLoading,
            setUploadFilesData,
            handleUploadFile,
            baseUrl,
            handleLogOut,
        ]
    );

    const moneyMarketValue = watch('MoneyMarket');
    const fixedIncomeValue = watch('FixedIncome');
    const balanceValue = watch('Balance');
    const alternativesValue = watch('Alternatives');
    const equityValue = watch('Equity');
    const fundOfFundsValue = watch('FundsOfFunds');
    const othersValueProductSegmentation = watch('othersProductSegmentation');

    const retailValue = watch('Retail');
    const institutionalValue = watch('Institutional');
    const captiveValue = watch('Captive');
    const othersValueClientSegmentation = watch('othersClientSegmentation');

    const yOneValue = watch('yOne');
    const yTwoValue = watch('yTwo');
    const yThreeValue = watch('yThree');
    const yFourValue = watch('yFour');
    const yFiveValue = watch('yFive');

    const searchByDateOfUploadedFile = watch('searchByDateOfUploadedFile');
    const showDueDiligence = watch('showDueDiligence');

    const searchValue = watch('searchValue');

    const inceptionDateLastUpdate = watch('inceptionDateLastUpdate');
    const inceptionDateLastUpdatedName = watch('inceptionDateLastUpdatedName');

    const structuralDescriptionLastUpdate = watch(
        'structuralDescriptionLastUpdate'
    );
    const structuralDescriptionUpdatedName = watch(
        'structuralDescriptionUpdatedName'
    );

    const amhOverviewLastUpdate = watch('amhOverviewLastUpdate');
    const amhOverviewLastUpdatedName = watch('amhOverviewLastUpdatedName');

    const allfundsLinkedIdLastUpdate = watch('allfundsLinkedIdLastUpdate');
    const allfundsLinkedIdLastUpdatedName = watch(
        'allfundsLinkedIdLastUpdatedName'
    );

    const productSegmentationLastUpdate = watch(
        'productSegmentationLastUpdate'
    );
    const productSegmentationLastUpdatedName = watch(
        'productSegmentationLastUpdatedName'
    );

    const productSegmentationGraphLastUpdate = watch(
        'productSegmentationGraphLastUpdate'
    );
    const productSegmentationGraphUpdatedName = watch(
        'productSegmentationGraphUpdatedName'
    );

    const clientSegmentationGraphLastUpdate = watch(
        'clientSegmentationGraphLastUpdate'
    );
    const clientSegmentationGraphUpdatedName = watch(
        'clientSegmentationGraphUpdatedName'
    );
    const nameValue = watch('name');
    const aumLastFiveGraphLastUpdate = watch('aumLastFiveGraphLastUpdate');
    const aumLastFiveGraphUpdatedName = watch('aumLastFiveGraphUpdatedName');

    const securitiesLendingGraphLastUpdate = watch(
        'securitiesLendingGraphLastUpdate'
    );
    const securitiesLendingGraphUpdatedName = watch(
        'securitiesLendingGraphUpdatedName'
    );

    const syntheticProgramGraphLastUpdate = watch(
        'syntheticProgramGraphLastUpdate'
    );
    const syntheticProgramGraphUpdatedName = watch(
        'syntheticProgramGraphUpdatedName'
    );

    const swingFactorsGraphLastUpdate = watch('swingFactorsGraphLastUpdate');
    const swingFactorsGraphUpdatedName = watch('swingFactorsGraphUpdatedName');

    const aumConcentrationLastUpdate = watch('aumConcentrationLastUpdate');
    const aumConcentrationUpdatedName = watch('aumConcentrationUpdatedName');

    const externalAuditorLastUpdate = watch('externalAuditorLastUpdate');
    const externalAuditorUpdatedName = watch('externalAuditorUpdatedName');

    const aumStabilityLastUpdate = watch('aumStabilityLastUpdate');
    const aumStabilityUpdatedName = watch('aumStabilityUpdatedName');

    const administratorLastUpdate = watch('administratorLastUpdate');
    const administratorUpdatedName = watch('administratorUpdatedName');

    const custodianLastUpdate = watch('custodianLastUpdate');
    const custodianUpdatedName = watch('custodianUpdatedName');

    const languagesValue = watch('languages');

    const barChartsValueProductSegmentation = useMemo(() => {
        return [
            +(moneyMarketValue ?? 0),
            +(fixedIncomeValue ?? 0),
            +(balanceValue ?? 0),
            +(alternativesValue ?? 0),
            +(equityValue ?? 0),
            +(fundOfFundsValue ?? 0),
            +(othersValueProductSegmentation ?? 0),
        ];
    }, [
        moneyMarketValue,
        fixedIncomeValue,
        balanceValue,
        alternativesValue,
        equityValue,
        fundOfFundsValue,
        othersValueProductSegmentation,
    ]);

    const barChartsValueClientSegmentation = useMemo(() => {
        return [
            +(retailValue ?? 0),
            +(institutionalValue ?? 0),
            +(captiveValue ?? 0),
            +(othersValueClientSegmentation ?? 0),
        ];
    }, [
        retailValue,
        institutionalValue,
        captiveValue,
        othersValueClientSegmentation,
    ]);

    const barChartsValueAum = useMemo(() => {
        return [
            +(yOneValue ?? 0),
            +(yTwoValue ?? 0),
            +(yThreeValue ?? 0),
            +(yFourValue ?? 0),
            +(yFiveValue ?? 0),
        ];
    }, [yOneValue, yTwoValue, yThreeValue, yFourValue, yFiveValue]);

    const [tab, setTab] = useState('GENERAL INFORMATION');

    const handleChangeTab = useCallback(
        (event: React.SyntheticEvent, newValue: string) => {
            setTab(newValue);
        },
        [setTab]
    );

    const handleChangeSectionByTab = useCallback(() => {
        if (tab === 'GENERAL INFORMATION') {
            return (
                <GeneralInformation
                    control={control}
                    inceptionDateLastUpdate={inceptionDateLastUpdate}
                    inceptionDateLastUpdatedName={inceptionDateLastUpdatedName}
                    structuralDescriptionLastUpdate={
                        structuralDescriptionLastUpdate
                    }
                    structuralDescriptionUpdatedName={
                        structuralDescriptionUpdatedName
                    }
                    amhOverviewLastUpdate={amhOverviewLastUpdate}
                    amhOverviewLastUpdatedName={amhOverviewLastUpdatedName}
                    allfundsLinkedIdLastUpdate={allfundsLinkedIdLastUpdate}
                    allfundsLinkedIdLastUpdatedName={
                        allfundsLinkedIdLastUpdatedName
                    }
                />
            );
        } else if (tab === 'SEGMENTATION') {
            return (
                <Segmentation
                    control={control}
                    barChartsValueProductSegmentation={
                        barChartsValueProductSegmentation
                    }
                    barChartsValueClientSegmentation={
                        barChartsValueClientSegmentation
                    }
                    barChartsValueAum={barChartsValueAum}
                    productSegmentationLastUpdate={
                        productSegmentationLastUpdate
                    }
                    productSegmentationLastUpdatedName={
                        productSegmentationLastUpdatedName
                    }
                    productSegmentationGraphLastUpdate={
                        productSegmentationGraphLastUpdate
                    }
                    productSegmentationGraphUpdatedName={
                        productSegmentationGraphUpdatedName
                    }
                    clientSegmentationGraphLastUpdate={
                        clientSegmentationGraphLastUpdate
                    }
                    clientSegmentationGraphUpdatedName={
                        clientSegmentationGraphUpdatedName
                    }
                    aumLastFiveGraphLastUpdate={aumLastFiveGraphLastUpdate}
                    aumLastFiveGraphUpdatedName={aumLastFiveGraphUpdatedName}
                    securitiesLendingGraphLastUpdate={
                        securitiesLendingGraphLastUpdate
                    }
                    securitiesLendingGraphUpdatedName={
                        securitiesLendingGraphUpdatedName
                    }
                    syntheticProgramGraphLastUpdate={
                        syntheticProgramGraphLastUpdate
                    }
                    syntheticProgramGraphUpdatedName={
                        syntheticProgramGraphUpdatedName
                    }
                    swingFactorsGraphLastUpdate={swingFactorsGraphLastUpdate}
                    swingFactorsGraphUpdatedName={swingFactorsGraphUpdatedName}
                    dataForYAxisAUM={dataForYAxisAUM}
                />
            );
        } else if (tab === 'SCORE CARD') {
            return (
                <ScoreCard
                    control={control}
                    aumConcentrationLastUpdate={aumConcentrationLastUpdate}
                    aumConcentrationUpdatedName={aumConcentrationUpdatedName}
                    externalAuditorLastUpdate={externalAuditorLastUpdate}
                    externalAuditorUpdatedName={externalAuditorUpdatedName}
                    aumStabilityLastUpdate={aumStabilityLastUpdate}
                    aumStabilityUpdatedName={aumStabilityUpdatedName}
                    administratorLastUpdate={administratorLastUpdate}
                    administratorUpdatedName={administratorUpdatedName}
                    custodianLastUpdate={custodianLastUpdate}
                    custodianUpdatedName={custodianUpdatedName}
                />
            );
        } else if (tab === 'FILE UPLOAD') {
            return (
                <FileUpload
                    loading={loading}
                    handleCloseDialog={handleCloseDialog}
                    handleOpenDialog={handleOpenDialog}
                    isDialogOpen={isDialogOpen}
                    handleDeleteUploadedFile={handleDeleteUploadedFile}
                    control={control}
                    searchValue={searchValue}
                    searchByDateOfUploadedFile={searchByDateOfUploadedFile}
                    showDueDiligence={showDueDiligence}
                    uploadFilesData={uploadFilesData}
                    loadingData={loadingData}
                    selected={selected}
                    setSelected={setSelected}
                />
            );
        }
    }, [
        loading,
        handleCloseDialog,
        handleOpenDialog,
        isDialogOpen,
        handleDeleteUploadedFile,
        selected,
        setSelected,
        loadingData,
        tab,
        control,
        barChartsValueProductSegmentation,
        barChartsValueClientSegmentation,
        barChartsValueAum,
        searchValue,
        searchByDateOfUploadedFile,
        showDueDiligence,
        inceptionDateLastUpdate,
        inceptionDateLastUpdatedName,
        amhOverviewLastUpdate,
        amhOverviewLastUpdatedName,
        allfundsLinkedIdLastUpdate,
        allfundsLinkedIdLastUpdatedName,
        productSegmentationLastUpdate,
        productSegmentationLastUpdatedName,
        productSegmentationGraphLastUpdate,
        productSegmentationGraphUpdatedName,
        clientSegmentationGraphLastUpdate,
        clientSegmentationGraphUpdatedName,
        aumLastFiveGraphLastUpdate,
        aumLastFiveGraphUpdatedName,
        securitiesLendingGraphLastUpdate,
        securitiesLendingGraphUpdatedName,
        syntheticProgramGraphLastUpdate,
        syntheticProgramGraphUpdatedName,
        swingFactorsGraphLastUpdate,
        swingFactorsGraphUpdatedName,
        aumConcentrationLastUpdate,
        aumConcentrationUpdatedName,
        externalAuditorLastUpdate,
        externalAuditorUpdatedName,
        aumStabilityLastUpdate,
        aumStabilityUpdatedName,
        administratorLastUpdate,
        administratorUpdatedName,
        custodianLastUpdate,
        custodianUpdatedName,
        dataForYAxisAUM,
        uploadFilesData,
    ]);

    const tabsArray = [
        {
            tabValue: 'GENERAL INFORMATION',
            tabLabel: 'General information',
        },
        {
            tabValue: 'SEGMENTATION',
            tabLabel: 'Segmentation',
        },
        {
            tabValue: 'SCORE CARD',
            tabLabel: 'Score card',
        },
        {
            tabValue: 'FILE UPLOAD',
            tabLabel: 'File upload',
        },
    ];

    const handleGetIndependentRiskValueId = useCallback((value: string) => {
        if (value === 'Yes') {
            return 1;
        } else if (value === 'Yes with Investment Team') {
            return 2;
        } else {
            return 3;
        }
    }, []);

    const downloadFile = async () => {
        const url = 'api/Manager/';
        const languageId = handleGetLanguageIdFromSelect(
            languagesValue as string
        );

        try {
            const apiUrl = process.env.REACT_APP_AFB_DQR_API;
            const response = await fetch(
                apiUrl + `${url + riskId}/Scorecard/${languageId}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            console.log(response);
            if (!response.ok) {
                if (response.status === 401) {
                    handleLogOut();
                }
                const errorText = await response.text();
                throw new HttpError(response.status, `Error ${response.status}: ${errorText}`);
            }

            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            const contentDispositionHeader = response.headers.get(
                'content-disposition'
            );
            console.log({ contentDispositionHeader });
            const matches = contentDispositionHeader?.match(
                /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            );
            const fileName =
                matches && matches[1] ? matches[1] : 'Report.pdf';
            console.log({ fileName });
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            if (link.parentNode) {
                link.parentNode.removeChild(link);
            }
        } catch (error) {
            const typedError = error as HttpError;
            if (typedError.response?.status === 422) {
                handleManageToast(
                    'error',
                    'Some of the parameters needed to generate the Scorecard are null'
                );
            }else{
                handleManageToast(
                    'error',
                    'Unexpected error generating file'
                );
            }
            console.error('Error downloading the report: ', error);
        }
    }


    const onSubmit: SubmitHandler<IFormInputs> = useCallback(
        async ({
            companyInceptionDate,
            MoneyMarket,
            FixedIncome,
            Balance,
            Alternatives,
            Equity,
            FundsOfFunds,
            othersProductSegmentation,
            totalProductSegmentation,
            Retail,
            Institutional,
            Captive,
            othersClientSegmentation,
            totalClientSegmentation,
            yOne,
            yTwo,
            yThree,
            yFour,
            yFive,
            esgPolicy,
            donations,
            unpriSignatory,
            oecdRegulator,
            independentAdmin,
            independentCustodian,
            thirdPartyStability,
            governanceStability,
            bigFourAuditor,
            allfunds_Id,
            sentToConnect,
            independentRisk,
            languages,
            structuralDescription,
            amhOverviewHighlights,
            aumConcentration,
            auditor,
            stability,
            administrator,
            custodian,
            name
        }) => {
            handleOpenBackdrop();
            try {
                const apiUrlManager = `${baseUrl}Manager/${riskId}`;
                const apiUrlTranslations = `${baseUrl}Manager/${riskId}/Translation`;

                const todayFormatted = dayjs().format(
                    'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
                );
                const yValuesAum = [yOne, yTwo, yThree, yFour, yFive]?.map(
                    (value) => Number(value)
                );
                const yValuesAumReverse = yValuesAum.reverse();
                const pairedDataAum = dataForYAxisAUMYears?.map(
                    (year, index) => {
                        return [year, yValuesAumReverse[index]];
                    }
                );

                const totalClientSegmentationStr =
                    totalClientSegmentation?.toString() + '.0000';

                const clientSegmentationValues = [
                    { Retail: Retail },
                    { Institutional: Institutional },
                    { Captive: Captive },
                    { Others: othersClientSegmentation },
                    { Total: totalClientSegmentationStr },
                ];
                const totalProductSegmentationStr =
                    totalProductSegmentation?.toString() + '.0000';

                const productSegmentationValues = [
                    { MoneyMarket: MoneyMarket },
                    { FixedIncome: FixedIncome },
                    { Balance: Balance },
                    { Alternatives: Alternatives },
                    { Equity: Equity },
                    { FundsOfFunds: FundsOfFunds },
                    { Others: othersProductSegmentation },
                    { Total: totalProductSegmentationStr },
                ];

                const dataManager = {
                    id: riskId,
                    startDate: companyInceptionDate?.format(
                        'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
                    ),
                    aum: JSON.stringify(pairedDataAum),
                    productSegmentation: JSON.stringify(
                        productSegmentationValues
                    ),
                    clientSegmentation: JSON.stringify(
                        clientSegmentationValues
                    ),
                    esgPolicy: handleTransformToBooleanValue(
                        esgPolicy as string
                    ),
                    donations: handleTransformToBooleanValue(
                        donations as string
                    ),
                    unpriSignatory: handleTransformToBooleanValue(
                        unpriSignatory as string
                    ),
                    oecdRegulator: handleTransformToBooleanValue(
                        oecdRegulator as string
                    ),
                    independentAdmin: handleTransformToBooleanValue(
                        independentAdmin as string
                    ),
                    independentCustodian: handleTransformToBooleanValue(
                        independentCustodian as string
                    ),
                    thirdPartyStability: handleTransformToBooleanValue(
                        thirdPartyStability as string
                    ),
                    governanceStability: handleTransformToBooleanValue(
                        governanceStability as string
                    ),
                    bigFourAuditor: handleTransformToBooleanValue(
                        bigFourAuditor as string
                    ),
                    allfunds_Id: allfunds_Id && +allfunds_Id,
                    independentRisk_Id: handleGetIndependentRiskValueId(
                        independentRisk as string
                    ),
                    auditor: auditor,
                    stability: stability,
                    administrator: administrator,
                    custodian: custodian,
                    sentToConnect: sentToConnect,
                    name: name
                };

                const languageId = handleGetLanguageIdFromSelect(
                    languages as string
                );
                const languageItem = handleGetLanguageItem(
                    managerData?.translations as Translation[],
                    languageId as number
                );

                const dataTranslations = {
                    id: languageItem ? languageItem['id'] : newId,
                    manager_Id: riskId,
                    language_Id: languageId,
                    structuralDescription: structuralDescription,
                    amhOverviewHighlights: amhOverviewHighlights,
                    aumConcentration: aumConcentration,
                };

                const putRequest = axios.put(apiUrlManager, dataManager, {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const postRequest = axios.post(
                    apiUrlTranslations,
                    dataTranslations,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

                const results = await Promise.allSettled([putRequest, postRequest]);

                let allResponses409 = true;

                let putResponse, postResponse;
                let putError, postError;

                if (results[0].status === 'fulfilled') {
                    putResponse = results[0].value;
                    if (putResponse.status !== 409) {
                        allResponses409 = false;
                    }
                } else if (results[0].status === 'rejected') {
                    putError = results[0].reason;
                    if (putError?.response?.status !== 409) {
                        allResponses409 = false;
                    }
                }

                if (results[1].status === 'fulfilled') {
                    postResponse = results[1].value;
                    if (postResponse.status !== 409) {
                        allResponses409 = false;
                    }
                } else if (results[1].status === 'rejected') {
                    postError = results[1].reason;
                    if (postError?.response?.status !== 409) {
                        allResponses409 = false;
                    }
                }

                if (allResponses409) {
                    handleManageToast('error', 'There was no change in the entity');
                } else {
                    handleManageToast('success', 'Data saved successfully');
                }

                if (actionAfterSubmitRef.current === 'Generate') {
                    handleManageToast('success', 'Generating Scorecard');
                    await downloadFile();
                }
            } catch (error) {
                const typedError = error as {
                    response?: {
                        status?: number;
                        data?: {
                            errors?: {
                                '$.allfunds_Id'?: string[];
                            };
                        };
                    };
                };

                if (typedError.response?.status === 401) {
                    handleLogOut();
                } else if (typedError.response?.status === 400) {
                    if (typedError.response?.data?.errors?.['$.allfunds_Id']) {
                        handleManageToast('error', 'Invalid Allfund linked id, use a valid one');
                    } else {
                        console.log('error', error);
                        handleManageToast('error', 'There was no change in the entity');
                    }
                } else {
                    handleManageToast('error', 'There was an unexpected error trying to save the data');
                }
            } finally {
                handleCloseBackdrop();
                setRefetchData(prev => !prev);
            }
        },
        [
            managerData,
            handleGetLanguageIdFromSelect,
            handleGetLanguageItem,
            dataForYAxisAUMYears,
            handleTransformToBooleanValue,
            handleGetIndependentRiskValueId,
            handleOpenBackdrop,
            handleCloseBackdrop,
            handleManageToast,
            baseUrl,
            riskId,
            accessToken,
            handleLogOut,
        ]
    );

    const handleAction = (action: 'Save' | 'Generate') => {
        actionAfterSubmitRef.current = action;
        handleSubmit(onSubmit)();
    };

    const handleTotalBarChartValue = useCallback(
        (barChartsValues: number[], chartValue: string) => {
            if (barChartsValues) {
                const totalValue = barChartsValues.reduce(
                    (sum, value) => sum + value,
                    0
                );

                if (totalValue) {
                    setValue(
                        chartValue as
                            | 'totalProductSegmentation'
                            | 'totalClientSegmentation',

                        totalValue.toString()
                    );
                } else {
                    setValue(
                        chartValue as
                            | 'totalProductSegmentation'
                            | 'totalClientSegmentation',
                        ''
                    );
                }
            }
        },
        [setValue]
    );

    const handleGetIndependentRiskValue = useCallback((id: number) => {
        if (id === 1) {
            return IndependentRiskValue.yesValue;
        } else if (id === 2) {
            return IndependentRiskValue.yesWithInvestmentTeamValue;
        } else {
            return IndependentRiskValue.noValue;
        }
    }, []);

    function isValueWithLastModification<T>(
        value: unknown
    ): value is ValueWithLastModification<T> {
        return (
            typeof value === 'object' &&
            value !== null &&
            'value' in value &&
            'lastModification' in value
        );
    }

    const processData = useCallback(
        (initialArray: Array<[string, number]>) => {
            const numbers = initialArray?.map((item) => item[1]);

            const highestNumber = numbers && Math.max(...numbers);

            const magnitude = Math.pow(
                10,
                Math.floor(Math.log10(highestNumber))
            );

            const newHighestNumber = highestNumber + magnitude;

            setDataForYAxisAUM([
                newHighestNumber / 4,
                newHighestNumber / 2,
                newHighestNumber,
            ]);
        },
        [setDataForYAxisAUM]
    );

    useEffect(() => {
        handleGetLanguages();
    }, [handleGetLanguages]);

    useEffect(() => {
        fetchManagerData(riskId as string);
    }, [fetchManagerData, refetchData, riskId]);

    useEffect(() => {
        if (uploadedValues?.length !== 0) {
            handleUploadFile(uploadedValues && uploadedValues[0]);
        }
    }, [uploadedValues, handleUploadFile]);

    useEffect(() => {
        if (barChartsValueProductSegmentation) {
            handleTotalBarChartValue(
                barChartsValueProductSegmentation,
                'totalProductSegmentation'
            );
        }
    }, [handleTotalBarChartValue, barChartsValueProductSegmentation]);

    useEffect(() => {
        if (barChartsValueClientSegmentation) {
            handleTotalBarChartValue(
                barChartsValueClientSegmentation,
                'totalClientSegmentation'
            );
        }
    }, [handleTotalBarChartValue, barChartsValueClientSegmentation]);

    useEffect(() => {
        const dataArray: ManagerDataEntry[] = Object.keys(
            managerData ? managerData : {}
        )
            ?.map((key) => {
                const typedKey = key as keyof ManagerData;
                return {
                    key: typedKey,
                    value: managerData && managerData[typedKey],
                } as ManagerDataEntry;
            })
            .filter((entry) => entry?.value !== undefined);

        setManagerDataArray(dataArray);
    }, [managerData]);

    useEffect(() => {
        if (managerDataArray) {
            managerDataArray?.forEach((item) => {
                if (
                    isValueWithLastModification<boolean>(item?.value) &&
                    typeof item?.value?.value === 'boolean' &&
                    item?.key !== 'sentToConnect'
                ) {
                    const tranformedBooleanValue = handleTransformBooleanValue(
                        item?.value?.value as boolean
                    );

                    setValue(
                        item?.key as SegmentationButtonGroup,
                        tranformedBooleanValue
                    );
                }

                if (item?.key === 'translations') {
                    const languageId = handleGetLanguageIdFromSelect(
                        languagesValue as string
                    );
                    const languageItem = handleGetLanguageItem(
                        item.value as Translation[],
                        languageId as number
                    );
                    if (languageItem) {
                        Object.keys(languageItem)?.forEach((translationKey) => {
                            const translationValue: unknown =
                                languageItem[
                                    translationKey as keyof Translation
                                ];
                            if (
                                translationValue &&
                                typeof translationValue === 'object' &&
                                'value' in translationValue &&
                                typeof translationValue.value === 'string'
                            ) {
                                setValue(
                                    translationKey as TexareaValue,
                                    translationValue.value
                                );
                            }
                        });
                    }
                }
            });
        }
    }, [
        managerDataArray,
        setValue,
        handleTransformBooleanValue,
        languagesValue,
        handleGetLanguageIdFromSelect,
        handleGetLanguageItem,
    ]);

    useEffect(() => {
        if (managerData) {
            const productSegmentationData =
                managerData?.productSegmentation?.value &&
                JSON.parse(managerData?.productSegmentation?.value as string);
            const clientSegmentationData =
                managerData?.clientSegmentation?.value &&
                JSON.parse(managerData?.clientSegmentation?.value as string);
            const aumData: Array<[string, number]> | null = managerData?.aum
                ?.value
                ? JSON.parse(managerData?.aum?.value as string)
                : [
                      ['2014', 0],

                      ['2015', 0],

                      ['2016', 0],

                      ['2017', 0],

                      ['2018', 0],
                  ];
            const years = aumData?.map((item) => item[0]);

            setDataForYAxisAUMYears(years as string[]);

            processData(aumData as [string, number][]);
            const fieldNames: Array<keyof IFormInputs> = [
                'yFive',
                'yFour',
                'yThree',
                'yTwo',
                'yOne',
            ];

            aumData?.forEach((item, index) => {
                if (index < fieldNames.length) {
                    setValue(fieldNames[index], item[1]);
                }
            });

            productSegmentationData?.forEach(
                (element: ProductSegmentationValue) => {
                    Object.entries(element)?.forEach(([key, value]) => {
                        const processedValue = value ?? 0;

                        if (key !== 'Total' && key !== 'Others') {
                            setValue(key as ProductSegmentationValues, processedValue);
                        } else if (key === 'Others') {
                            setValue('othersProductSegmentation', processedValue);
                        }
                    });
                }
            );

            clientSegmentationData?.forEach(
                (element: ClientSegmentationValues) => {
                    Object.entries(element)?.forEach(([key, value]) => {
                        const processedValue = value ?? 0;

                        if (key !== 'Total' && key !== 'Others') {
                            setValue(key as ProductSegmentationValues, processedValue);
                        } else if (key === 'Others') {
                            setValue('othersClientSegmentation', processedValue);
                        }
                    });
                }
            );

            setValue(
                'productSegmentationGraphLastUpdate',
                dayjs(
                    managerData?.productSegmentation?.lastModification
                        ?.lastModifiedDate
                ).format('DD/MM/YYYY')
            );
            setValue(
                'productSegmentationGraphUpdatedName',
                managerData?.productSegmentation?.lastModification
                    ?.lastModifiedBy
            );

            setValue(
                'clientSegmentationGraphLastUpdate',
                dayjs(
                    managerData?.clientSegmentation?.lastModification
                        ?.lastModifiedDate
                ).format('DD/MM/YYYY')
            );
            setValue(
                'clientSegmentationGraphUpdatedName',
                managerData?.clientSegmentation?.lastModification
                    ?.lastModifiedBy
            );

            setValue(
                'aumLastFiveGraphLastUpdate',
                dayjs(
                    managerData?.aum?.lastModification?.lastModifiedDate
                ).format('DD/MM/YYYY')
            );
            setValue(
                'aumLastFiveGraphUpdatedName',
                managerData?.aum?.lastModification?.lastModifiedBy
            );

            setValue('sentToConnect', managerData.sentToConnect?.value);
            setValue('allfunds_Id', managerData.allfunds_Id?.value);
            setValue(
                'allfundsLinkedIdLastUpdate',
                dayjs(
                    managerData.allfunds_Id?.lastModification?.lastModifiedDate
                ).format('DD/MM/YYYY')
            );
            setValue(
                'allfundsLinkedIdLastUpdatedName',
                managerData?.allfunds_Id?.lastModification?.lastModifiedBy
            );

            setValue('auditor', managerData.auditor?.value);
            setValue(
                'externalAuditorLastUpdate',
                dayjs(
                    managerData.auditor?.lastModification?.lastModifiedDate
                ).format('DD/MM/YYYY')
            );
            setValue(
                'externalAuditorUpdatedName',
                managerData?.auditor?.lastModification?.lastModifiedBy
            );

            setValue('name', managerData.name);
            setValue('stability', managerData.stability?.value);
            setValue(
                'aumStabilityLastUpdate',
                dayjs(
                    managerData.stability?.lastModification?.lastModifiedDate
                ).format('DD/MM/YYYY')
            );
            setValue(
                'aumStabilityUpdatedName',
                managerData?.stability?.lastModification?.lastModifiedBy
            );

            setValue('administrator', managerData.administrator?.value);
            setValue(
                'administratorLastUpdate',
                dayjs(
                    managerData.administrator?.lastModification?.lastModifiedDate
                ).format('DD/MM/YYYY')
            );
            setValue(
                'administratorUpdatedName',
                managerData?.administrator?.lastModification?.lastModifiedBy
            );

            setValue('custodian', managerData.custodian?.value);
            setValue(
                'custodianLastUpdate',
                dayjs(
                    managerData.custodian?.lastModification?.lastModifiedDate
                ).format('DD/MM/YYYY')
            );
            setValue(
                'custodianUpdatedName',
                managerData?.custodian?.lastModification?.lastModifiedBy
            );

            setValue(
                'companyInceptionDate',
                dayjs(managerData?.startDate?.value)
            );

            setValue(
                'inceptionDateLastUpdate',
                dayjs(
                    managerData?.startDate?.lastModification?.lastModifiedDate
                ).format('DD/MM/YYYY')
            );
            setValue(
                'inceptionDateLastUpdatedName',
                managerData?.startDate?.lastModification?.lastModifiedBy
            );

            if (managerData.translations) {
                const languageId = handleGetLanguageIdFromSelect(
                    languagesValue as string
                );
                const languageItem = handleGetLanguageItem(
                    managerData?.translations as Translation[],
                    languageId as number
                );

                setValue(
                    'structuralDescriptionLastUpdate',
                    dayjs(
                        languageItem?.structuralDescription?.lastModification
                            ?.lastModifiedDate
                    ).format('DD/MM/YYYY')
                );
                setValue(
                    'structuralDescriptionUpdatedName',

                    languageItem?.structuralDescription?.lastModification
                        ?.lastModifiedBy
                );

                setValue(
                    'amhOverviewLastUpdate',
                    dayjs(
                        languageItem?.amhOverviewHighlights?.lastModification
                            ?.lastModifiedDate
                    ).format('DD/MM/YYYY')
                );

                setValue(
                    'amhOverviewLastUpdatedName',

                    languageItem?.amhOverviewHighlights?.lastModification
                        ?.lastModifiedBy
                );

                setValue(
                    'aumConcentrationLastUpdate',
                    dayjs(
                        languageItem?.aumConcentration?.lastModification
                            ?.lastModifiedDate
                    ).format('DD/MM/YYYY')
                );

                setValue(
                    'aumConcentrationUpdatedName',

                    languageItem?.aumConcentration?.lastModification
                        ?.lastModifiedBy
                );
            }

            if (managerData.independentRisk_Id) {
                setValue(
                    'independentRisk',
                    handleGetIndependentRiskValue(
                        managerData?.independentRisk_Id.value
                    )
                );
            }
        }
    }, [
        managerData,
        setValue,
        handleGetIndependentRiskValue,
        handleGetLanguageIdFromSelect,
        handleGetLanguageItem,
        languagesValue,
        processData,
        setDataForYAxisAUMYears,
    ]);

    useEffect(() => {
        const yValues = [
            Number(yOneValue) || 0,
            Number(yTwoValue) || 0,
            Number(yThreeValue) || 0,
            Number(yFourValue) || 0,
            Number(yFiveValue) || 0,
        ];

        const updatedAumData = dataForYAxisAUMYears.map((year, index) => [
            year,
            yValues[index],
        ]);

        processData(updatedAumData as [string, number][]);
    }, [
        yOneValue,
        yTwoValue,
        yThreeValue,
        yFourValue,
        yFiveValue,
        processData,
        dataForYAxisAUMYears,
    ]);

    if (loading && !isDialogOpen) return <Loading />;

    document.title = `AppHub - Dqr - Risk Detail`;
    return (
        <Box
            onSubmit={handleSubmit(onSubmit)}
            component={'form'}
            width={'100%'}
        >
            <Grid container gap={3}>
                <TabComponent
                    tab={tab}
                    handleChangeTab={handleChangeTab}
                    tabsArray={tabsArray}
                />

                <Grid item sx={{ width: '200px' }}>
                    <OptionSelect
                        control={control}
                        data={languagesData}
                        name='languages'
                        labelName='Languages'
                        label
                    />
                </Grid>

                <Box alignSelf={'center'}>
                    <Typography  variant='h6'>
                        {String(nameValue)}
                    </Typography>
                </Box>
            </Grid>
            <Grid
                container
                flexDirection={'column'}
                sx={{ marginTop: 1 }}
                gap={3}
            >
                <Grid item>{handleChangeSectionByTab()}</Grid>
            </Grid>

            <Divider />

            <Grid
                container
                item
                justifyContent={'flex-end'}
                sx={{ margin: '17px 0' }}
                gap={2}
            >
                <Button
                    key= 'Save'
                    type= 'button'
                    color='success'
                    variant='contained'
                    size='large'
                    onClick={() => handleAction('Save')}
                >
                    Save
                </Button>
                <Button
                    key= 'Generate'
                    type= 'button'
                    color='warning'
                    variant='contained'
                    size='large'
                    onClick={() => { handleAction('Generate'); }}
                >
                    Generate
                </Button>
            </Grid>
            <Toast
                handleCloseToast={handleCloseToast as () => void}
                openToast={openToast as boolean}
                severity={alertSeverity}
                message={alertMessage}
            />

            <SimpleBackdrop
                handleCloseBackdrop={handleCloseBackdrop as () => void}
                openBackdrop={openBackdrop as boolean}
            />
        </Box>
    );
};

export default RiskDetail;
