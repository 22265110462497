import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_EMAILS } from '../../graphql/queries/emails';
import { Box, Grid } from '@mui/material';
import Loading from '../loading/loading';
import { useForm } from 'react-hook-form';
import TabComponent from '../../components/tab/tab';
import OptionSelect from '../../components/option-select/option-select';
import { generateByData } from '../../data/shared/data-for-select';
import { EmailsData } from '../../types/email';
import { IFormInputs } from '../../types/shared';
import { useDataProcessingContext } from '../../context/data-processing-context';
import { columns } from '../../data/columns/emails';
import ErrorPage from '../error/error-page';
import CustomTable from '../../components/custom-table/custom-table';
import TextInput from '../../components/text-input/text-input';

function Emails() {
    document.title = `AppHub - Bnp - Send Emails`;
    const { selected, setSelected, handleChipStatus } =
        useDataProcessingContext();
    const { data, loading } = useQuery(GET_EMAILS);
    const [emailsData, setEmailsData] = useState<EmailsData[]>([]);
    const [tab, setTab] = useState('SENT');
    const [filteredEmailsData, setFilteredEmailsData] = useState<EmailsData[]>(
        []
    );
    const { control, watch } = useForm<IFormInputs>({
        defaultValues: {
            searchValue: '',
            type: 'BillingGroup',
        },
    });
    const searchValue = watch('searchValue');
    const typeValue = watch('type');

    const filterDataByStatus = useCallback(() => {
        const filteredData = emailsData.filter(({ status }) => status === tab);
        setFilteredEmailsData(filteredData);
    }, [emailsData, tab]);

    const handleChangeTab = useCallback(
        (event: React.SyntheticEvent, newValue: string) => {
            setTab(newValue);
        },
        []
    );

    const filterReports = useCallback(() => {
        if (!searchValue) {
            const filteredData = emailsData.filter(
                ({ status, generationType }) => {
                    return status === tab && generationType === typeValue;
                }
            );
            setFilteredEmailsData(filteredData);
        } else {
            const filteredData = emailsData.filter((email) => {
                const isSearchMatching =
                    email.status === tab &&
                    email.generationType === typeValue &&
                    (email.generationId
                        .toString()
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(
                            searchValue.toLowerCase().replace(/\s+/g, '')
                        ) ||
                        email.id
                            .toString()
                            .toLowerCase()
                            .replace(/\s+/g, '')
                            .includes(
                                searchValue.toLowerCase().replace(/\s+/g, '')
                            ) ||
                        email.providerName
                            .toString()
                            .toLowerCase()
                            .replace(/\s+/g, '')
                            .includes(
                                searchValue.toLowerCase().replace(/\s+/g, '')
                            ));
                return isSearchMatching;
            });
            setFilteredEmailsData(filteredData);
        }
    }, [searchValue, emailsData, tab, typeValue]);

    const rows = useMemo(() => {
        return filteredEmailsData.map(
            ({
                generationId,
                id,
                providerName,
                generationType,
                status,
                message,
            }) => {
                return {
                    generationId: generationId,
                    id: id,
                    providerName: providerName,
                    type: generationType,
                    status: status,
                    message: message,
                };
            }
        );
    }, [filteredEmailsData]);

    const tabsArray = [
        {
            tabValue: 'SENT',
            tabLabel: 'Sent',
        },
        {
            tabValue: 'REJECTED',
            tabLabel: 'Rejected',
        },
        {
            tabValue: 'ERROR',
            tabLabel: 'Error',
        },
    ];

    useEffect(() => {
        if (data) {
            setEmailsData(data.reportEmailStatus);
        }
    }, [data]);

    useEffect(() => {
        filterReports();
    }, [filterReports]);

    useEffect(() => {
        filterDataByStatus();
    }, [tab, filterDataByStatus]);

    if (loading) return <Loading />;

    return (
        <Box component={'article'} sx={{ width: '100%' }}>
            <Grid container gap={2} flexDirection='column'>
                <TabComponent
                    tab={tab}
                    handleChangeTab={handleChangeTab}
                    tabsArray={tabsArray}
                />

                {emailsData.length > 0 ? (
                    <>
                        <Grid item container gap={2} alignItems='center'>
                            <Grid item sx={{ width: '60%' }}>
                                <TextInput
                                    name='searchValue'
                                    control={control}
                                    labelName={
                                        'Search by generation id, id, or provider'
                                    }
                                />
                            </Grid>

                            <Grid item sx={{ width: '150px' }}>
                                <OptionSelect
                                    control={control}
                                    name='type'
                                    data={generateByData}
                                    labelId='select-type-label'
                                    label={true}
                                    selectId='select-type'
                                    labelName='Type'
                                />
                            </Grid>

                            {filteredEmailsData &&
                                filteredEmailsData.length > 0 && (
                                    <>
                                        <Grid item sx={{ width: '100%' }}>
                                            <CustomTable
                                                useCheckbox={false}
                                                selected={selected}
                                                setSelected={setSelected}
                                                columns={columns}
                                                rows={rows}
                                                handleChipStatus={
                                                    handleChipStatus
                                                }
                                            />
                                        </Grid>
                                    </>
                                )}
                        </Grid>

                        {filteredEmailsData?.length === 0 && (
                            <ErrorPage errorMessage='There are no emails to show' />
                        )}
                    </>
                ) : (
                    <ErrorPage errorMessage='There are no emails to show' />
                )}
            </Grid>
        </Box>
    );
}

export default Emails;
