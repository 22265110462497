import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

function ReturnButton() {
    const navigate = useNavigate();
    const goBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return (
        <Button color='info' startIcon={<ArrowBackIcon />} onClick={goBack}>
            Back
        </Button>
    );
}

export default ReturnButton;
