import React, {
    createContext,
    useCallback,
    useContext,
    useId,
    useState,
} from 'react';

import { SharedDataContext } from '../types/shared-data-context';
import { AfbStatus, PeerGroup } from '../types/research-list';
import { IFormInputs, Language, Translation } from '../types/shared';

import axios from 'axios';
import { useUIControlContext } from './ui-control-context';
import { SubmitHandler } from 'react-hook-form';
import { useStore } from '../store';
import { useLocation, useNavigate } from 'react-router-dom';

const context = createContext<SharedDataContext | undefined>(undefined);

export type SharedDataContextProviderProps = SharedDataContext & {
    children: React.ReactElement | null;
};

export function SharedDataContextProvider(
    props: SharedDataContextProviderProps
): React.ReactElement {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const newId = useId();
    const baseUrl = `${process.env.REACT_APP_AFB_DQR_API}api/`;
    const [peerGroupData, setPeerGroupData] = useState<PeerGroup[]>([]);
    const [peerGroupNames, setPeerGroupNames] = useState<string[]>([]);
    const [afbStatusData, setAfbStatusData] = useState<AfbStatus[]>([]);
    const [afbStatusNames, setAfbStatusNames] = useState<string[]>([]);
    const [languagesData, setLanguagesData] = useState<Language[]>([]);

    const accessToken = useStore((state) => state.accessToken);
    const { handleLogOut, setLoading, handleCloseDialog, handleManageToast } =
        useUIControlContext();

    const handleTransformBooleanValue: (value: boolean) => string | undefined =
        useCallback((value: boolean) => {
            if (value) {
                return 'Yes';
            } else {
                return 'No';
            }
        }, []);

    const handleTransformToBooleanValue: (
        value: string
    ) => boolean | undefined = useCallback((value: string) => {
        if (value === 'Yes') {
            return true;
        } else {
            return false;
        }
    }, []);

    const handleGetLanguageIdFromSelect = useCallback((language: string) => {
        if (language === 'English') {
            return 1;
        } else if (language === 'Spanish') {
            return 2;
        } else if (language === 'Deutsch') {
            return 3;
        }
    }, []);

    const handleGetLanguageItem = useCallback(
        (translations: Translation[], languageId: number) => {
            return translations?.find(
                (item) => item.language_Id === languageId
            );
        },
        []
    );

    const handleGetPeerGroup = useCallback(async () => {
        try {
            const response = await axios.get(`${baseUrl}PeerGroup`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.data.data) {
                setPeerGroupData(response.data.data);
                setPeerGroupNames(
                    Array.from(
                        new Set(
                            response.data.data.map(
                                (peerGroup: PeerGroup) => peerGroup?.name
                            )
                        )
                    )
                );
            }
        } catch (error) {
            const typedError = error as { response?: { status?: number } };

            if (typedError.response?.status === 401) {
                handleLogOut();
            } else {
                handleManageToast(
                    'error',
                    'Error trying to get the peer groups.'
                );
            }
        }
    }, [setPeerGroupData, setPeerGroupNames, baseUrl, accessToken]);

    const handleGetAfbStatusGroup = useCallback(async () => {
        try {
            const response = await axios.get(`${baseUrl}Status`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.data.data) {
                console.log('response afb status data', response);
                setAfbStatusData(response.data.data);
                const afbStatusNamesArray = response.data.data.map(
                    (afbStatus: AfbStatus) => afbStatus?.name
                );
                setAfbStatusNames(afbStatusNamesArray);
            }
        } catch (error) {
            const typedError = error as { response?: { status?: number } };

            if (typedError.response?.status === 401) {
                handleLogOut();
            } else {
                handleManageToast(
                    'error',
                    'Error trying to get the afb status.'
                );
            }
        }
    }, [
        baseUrl,
        setAfbStatusData,
        setAfbStatusNames,
        accessToken,
        handleLogOut,
    ]);

    const useEndpoint = useCallback((path: string) => {
        if (path === '/dqr/research-list') {
            return 'Fund';
        } else if (path === '/dqr/risk-list') {
            return 'Manager';
        }
    }, []);

    const handleGetLanguages = useCallback(async () => {
        try {
            const response = await axios.get(`${baseUrl}Language`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.data.data) {
                const languagesNames = response.data.data.map(
                    ({ name }: Language) => name
                );
                if (languagesNames) setLanguagesData(languagesNames);
            }
        } catch (error) {
            const typedError = error as { response?: { status?: number } };

            if (typedError.response?.status === 401) {
                handleLogOut();
            } else {
                handleManageToast(
                    'error',
                    'Error trying to get the languages.'
                );
            }
        }
    }, [setLanguagesData, baseUrl, accessToken]);

    const handleCreateEntity: SubmitHandler<IFormInputs> = useCallback(
        async ({ entityName }) => {
            setLoading(true);
            try {
                const dataCreateEntity = {
                    name: entityName,
                    independentRisk_Id: 3
                };

                const response = await axios.post(
                    `${baseUrl}${useEndpoint(pathname)}`,
                    dataCreateEntity,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

                if (response) {
                    handleCloseDialog();
                    navigate(`/dqr/risk-list/${response.data?.data?.id}`);
                    handleManageToast(
                        'success',
                        'Manager created successfully.'
                    );
                }
            } catch (error) {
                const typedError = error as { response?: { status?: number } };

                if (typedError.response?.status === 401) {
                    handleLogOut();
                } else if (typedError.response?.status === 400) {
                    handleManageToast('error', 'Manager already exists.');
                } else {
                    handleManageToast(
                        'error',
                        'Error creating manager. Please try again.'
                    );
                }
            } finally {
                setLoading(false);
            }
        },
        [
            accessToken,
            setLoading,
            handleLogOut,
            handleCloseDialog,
            handleManageToast,
            baseUrl,
            pathname,
        ]
    );

    const handleCreateFund: SubmitHandler<IFormInputs> = useCallback(
        async ({ entityName, entityIsin }) => {
            setLoading(true);
            try {
                const dataCreateEntity = {
                    name: entityName,
                    isin: entityIsin
                };

                const response = await axios.post(
                    `${baseUrl}${useEndpoint(pathname)}`,
                    dataCreateEntity,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

                if (response) {
                    handleCloseDialog();
                    navigate(`/dqr/research-list/${response.data?.data?.id}`);
                    handleManageToast(
                        'success',
                        'Fund created successfully.'
                    );
                }
            } catch (error) {
                const typedError = error as { response?: { status?: number } };

                if (typedError.response?.status === 401) {
                    handleLogOut();
                } else if (typedError.response?.status === 409) {
                    handleManageToast('error', 'Fund or Isin already exists.');
                } else {
                    handleManageToast(
                        'error',
                        'Error creating fund. Please try again.'
                    );
                }
            } finally {
                setLoading(false);
            }
        },
        [
            accessToken,
            setLoading,
            handleLogOut,
            handleCloseDialog,
            handleManageToast,
            baseUrl,
            pathname,
        ]
    );

    return (
        <context.Provider
            value={{
                peerGroupData,
                setPeerGroupData,
                peerGroupNames,
                setPeerGroupNames,
                afbStatusData,
                setAfbStatusData,
                afbStatusNames,
                setAfbStatusNames,
                handleTransformBooleanValue,
                handleGetLanguageIdFromSelect,
                handleGetLanguageItem,
                handleGetPeerGroup,
                baseUrl,
                handleGetAfbStatusGroup,
                handleTransformToBooleanValue,
                newId,
                handleCreateEntity,
                handleCreateFund,
                handleGetLanguages,
                languagesData,
            }}
        >
            {props.children}
        </context.Provider>
    );
}

export function useSharedDataContext(): SharedDataContext {
    const globalContext = useContext(context);

    if (globalContext === undefined) {
        throw new Error(
            'You must use `useSharedDataContext` hook within a component that is a descendant of a <SharedDataContextProvider />'
        );
    }

    return globalContext;
}
