import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { TableSortLabel } from '@mui/material';
import { useCallback } from 'react';
import { CustomTableHeadProps } from '../../../types/custom-table';
import { Columns, ColumnsName } from '../../../types/shared';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${visuallyHidden}`]: {
        border: 0,
    },
}));

const CustomTableHead = (props: CustomTableHeadProps) => {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        columns,
        useCheckbox,
    } = props;

    const createSortHandler = useCallback(
        (property: keyof ColumnsName) => () => {
            onRequestSort(property);
        },
        [onRequestSort]
    );

    const firtsColumnCheckbox = useCheckbox && (
        <StyledTableCell padding='checkbox'>
            <Checkbox
                color='primary'
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                    'aria-label': 'select all desserts',
                }}
            />
        </StyledTableCell>
    );

    const BoldTableCell = styled(TableCell)({
        fontWeight: 'bold',
    });

    const StyledTableHead = styled(TableHead)({
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        backgroundColor: 'background.paper', // Asegúrate de que el fondo sea sólido para mejorar el efecto
    });

    return (
        <StyledTableHead>
            <TableRow>
                {firtsColumnCheckbox}
                {columns.map(({ field, headerName }: Columns) => (
                    <BoldTableCell
                        key={field}
                        align='left'
                        padding='normal'
                        sortDirection={
                            orderBy === field
                                ? (order as 'asc' | 'desc')
                                : false
                        }
                    >
                        <TableSortLabel
                            active={orderBy === field}
                            direction={
                                orderBy === field ? (order as 'desc') : 'asc'
                            }
                            onClick={createSortHandler(
                                field as keyof ColumnsName
                            )}
                        >
                            {headerName}
                        </TableSortLabel>
                    </BoldTableCell>
                ))}
            </TableRow>
        </StyledTableHead>
    );
};

export default CustomTableHead;
