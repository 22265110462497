export const columns = [
    { field: 'fundFamily', headerName: 'Fund Family' },
    // {
    //     field: 'isin',
    //     headerName: 'ISIN',
    // },
    {
        field: 'peerGroup',
        headerName: 'Peer Group',
    },
    // {
    //     field: 'asset',
    //     headerName: 'Asset',
    // },
    {
        field: 'factsheetDate',
        headerName: 'Factsheet Date',
    },

    {
        field: 'afbStatus',
        headerName: 'AFB Status',
    },
    {
        field: 'action',
        headerName: 'Action',
    },
];
