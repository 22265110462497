import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import DatePickerInfo from '../../../../components/date-picker-info/date-picker-info';
import CustomTextarea from '../../../../components/custom-textarea/custom-textarea';
import { ControlProps } from '../../../../types/shared';
import TextInput from '../../../../components/text-input/text-input';
import FooterNote from '../../../../components/footer-note/footer-note';

interface GeneralInfoProps extends ControlProps {
    inceptionDateLastUpdate?: string;
    inceptionDateLastUpdatedName?: string;
    structuralDescriptionLastUpdate?: string;
    structuralDescriptionUpdatedName?: string;
    amhOverviewLastUpdate?: string;
    amhOverviewLastUpdatedName?: string;
    allfundsLinkedIdLastUpdate?: string;
    allfundsLinkedIdLastUpdatedName?: string;
}

function GeneralInformation({
    control,
    inceptionDateLastUpdate,
    inceptionDateLastUpdatedName,
    structuralDescriptionLastUpdate,
    structuralDescriptionUpdatedName,
    amhOverviewLastUpdate,
    amhOverviewLastUpdatedName,
    allfundsLinkedIdLastUpdate,
    allfundsLinkedIdLastUpdatedName,
}: GeneralInfoProps) {
    const selectData = useMemo(
        () => [
            {
                control: control,
                name: 'companyInceptionDate',
                labelName: 'Inception of the company',
                inceptionDateLastUpdate: inceptionDateLastUpdate,
                inceptionDateLastUpdatedName: inceptionDateLastUpdatedName,
            },
        ],
        [control, inceptionDateLastUpdate, inceptionDateLastUpdatedName]
    );

    const textareaData = useMemo(
        () => [
            {
                control: control,
                name: 'structuralDescription',
                labelName: 'Structural Description',
                updateDate: structuralDescriptionLastUpdate,
                updateName: structuralDescriptionUpdatedName,
            },
            {
                control: control,
                name: 'amhOverviewHighlights',
                labelName: 'AMH Overview',
                updateDate: amhOverviewLastUpdate,
                updateName: amhOverviewLastUpdatedName,
            },
        ],
        [
            control,
            structuralDescriptionLastUpdate,
            structuralDescriptionUpdatedName,
            amhOverviewLastUpdate,
            amhOverviewLastUpdatedName,
        ]
    );

    return (
        <Grid container gap={3}>
            {selectData.map(
                ({
                    control,
                    name,
                    labelName,
                    inceptionDateLastUpdate,
                    inceptionDateLastUpdatedName,
                }) => (
                    <Grid
                        container
                        flexDirection={'column'}
                        key={name}
                        gap={1}
                        sx={{
                            width: '100%',
                            marginTop: 1,
                        }}
                    >
                        <DatePickerInfo
                            control={control}
                            name={name}
                            labelName={labelName}
                        />
                        <FooterNote
                            date={inceptionDateLastUpdate}
                            name={inceptionDateLastUpdatedName}
                        />
                    </Grid>
                )
            )}

            {textareaData.map(
                ({ control, name, labelName, updateDate, updateName }) => (
                    <Grid
                        container
                        item
                        gap={1}
                        key={name}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '100%',
                                md: '100%',
                                lg: '45%',
                            },
                        }}
                    >
                        <CustomTextarea
                            name={name}
                            control={control}
                            labelName={labelName}
                            updateDate={updateDate}
                            updateName={updateName}
                        />
                    </Grid>
                )
            )}

            <Grid flexDirection={'column'} container item gap={1}>
                <TextInput
                    name='allfunds_Id'
                    control={control}
                    labelName={'Allfunds linked id'}
                />
                <FooterNote
                    date={allfundsLinkedIdLastUpdate}
                    name={allfundsLinkedIdLastUpdatedName}
                />
            </Grid>
        </Grid>
    );
}

export default GeneralInformation;
