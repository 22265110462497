import React, { useMemo } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { IFormInputs } from '../../../../types/shared';
import { Control } from 'react-hook-form';
import HighChartsComponent from '../../../../components/high-charts/high-charts';
import TextInput from '../../../../components/text-input/text-input';
import FooterNote from '../../../../components/footer-note/footer-note';

interface Props {
    control: Control<IFormInputs>;
    barChartsValueProductSegmentation: number[];
    barChartsValueClientSegmentation: number[];
    barChartsValueAum: number[];
    productSegmentationLastUpdate?: string;
    productSegmentationLastUpdatedName?: string;
    productSegmentationGraphLastUpdate?: string;
    productSegmentationGraphUpdatedName?: string;
    clientSegmentationGraphLastUpdate?: string;
    clientSegmentationGraphUpdatedName?: string;
    aumLastFiveGraphLastUpdate?: string;
    aumLastFiveGraphUpdatedName?: string;
    securitiesLendingGraphLastUpdate?: string;
    securitiesLendingGraphUpdatedName?: string;
    syntheticProgramGraphLastUpdate?: string;
    syntheticProgramGraphUpdatedName?: string;
    swingFactorsGraphLastUpdate?: string;
    swingFactorsGraphUpdatedName?: string;
    dataForYAxisAUM?: number[];
}

function Segmentation({
    control,
    barChartsValueProductSegmentation,
    barChartsValueClientSegmentation,
    barChartsValueAum,
    productSegmentationLastUpdate,
    productSegmentationLastUpdatedName,
    productSegmentationGraphLastUpdate,
    productSegmentationGraphUpdatedName,
    clientSegmentationGraphLastUpdate,
    clientSegmentationGraphUpdatedName,
    aumLastFiveGraphLastUpdate,
    aumLastFiveGraphUpdatedName,
    dataForYAxisAUM,
}: Props) {
    const selectData = useMemo(
        () => [
            {
                control: control,
                name: 'productSegmentationDate',
                labelName: 'Product Segmentation Date',
                updateDate: productSegmentationLastUpdate,
                updateName: productSegmentationLastUpdatedName,
            },
        ],
        [
            control,
            productSegmentationLastUpdate,
            productSegmentationLastUpdatedName,
        ]
    );
    const dataForYAxisClientProduct = useMemo(
        () => [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        []
    );

    const categoriesAUM = useMemo(
        () => ['Y-1', 'Y-2', 'Y-3', 'Y-4', 'Y-5'],
        []
    );
    const categoriesProductSegmentation = useMemo(
        () => [
            'Money Market',
            'Fixed Income',
            'Balance',
            'Alternatives',
            'Equity',
            'Fund of funds',
            'Others',
            'Total',
        ],
        []
    );

    const categoriesClientSegmentation = useMemo(
        () => ['Retail', 'Institutional', 'Captive', 'Others', 'Total'],
        []
    );
    const percentInputDataProductSegmentation = useMemo(
        () => [
            {
                control: control,
                name: 'MoneyMarket',
                labelName: 'Money Market',
                disabledInput: null,
            },
            {
                control: control,
                name: 'FixedIncome',
                labelName: 'Fixed Income',
                disabledInput: null,
            },
            {
                control: control,
                name: 'Balance',
                labelName: 'Balance',
                disabledInput: null,
            },
            {
                control: control,
                name: 'Alternatives',
                labelName: 'Alternatives',
                disabledInput: null,
            },
            {
                control: control,
                name: 'Equity',
                labelName: 'Equity',
                disabledInput: null,
            },
            {
                control: control,
                name: 'FundsOfFunds',
                labelName: 'Fund of funds',
                disabledInput: null,
            },
            {
                control: control,
                name: 'othersProductSegmentation',
                labelName: 'Others',
                disabledInput: null,
            },
            {
                control: control,
                name: 'totalProductSegmentation',
                labelName: 'Total',
                disabledInput: 'totalProductSegmentation',
            },
        ],
        [control]
    );

    const percentInputDataClientSegmentation = useMemo(
        () => [
            {
                control: control,
                name: 'Retail',
                labelName: 'Retail',
                disabledInput: null,
            },
            {
                control: control,
                name: 'Institutional',
                labelName: 'Institutional',
                disabledInput: null,
            },
            {
                control: control,
                name: 'Captive',
                labelName: 'Captive',
                disabledInput: null,
            },
            {
                control: control,
                name: 'othersClientSegmentation',
                labelName: 'Others',
                disabledInput: null,
            },
            {
                control: control,
                name: 'totalClientSegmentation',
                labelName: 'Total',
                disabledInput: 'totalClientSegmentation',
            },
        ],
        [control]
    );

    const percentInputDataAUM = useMemo(
        () => [
            {
                control: control,
                name: 'yOne',
                labelName: 'Y-1',
                disabledInput: null,
            },
            {
                control: control,
                name: 'yTwo',
                labelName: 'Y-2',
                disabledInput: null,
            },
            {
                control: control,
                name: 'yThree',
                labelName: 'Y-3',
                disabledInput: null,
            },
            {
                control: control,
                name: 'yFour',
                labelName: 'Y-4',
                disabledInput: null,
            },
            {
                control: control,
                name: 'yFive',
                labelName: 'Y-5',
                disabledInput: null,
            },
        ],
        [control]
    );

    const sections = useMemo(
        () => [
            {
                percentInputData: percentInputDataProductSegmentation,
                barChartsValue: barChartsValueProductSegmentation,
                categories: categoriesProductSegmentation,
                dataForYAxis: dataForYAxisClientProduct,
                isXAxisVisible: false,
                height: '490px',
                chartType: 'bar',
                labelValue: 'Product Segmentation',
                selectData: selectData,
                showLabelIcon: true,
                tooltipEndLabelText: 'percent',
                updateDate: productSegmentationGraphLastUpdate,
                updateName: productSegmentationGraphUpdatedName,
            },
            {
                percentInputData: percentInputDataClientSegmentation,
                barChartsValue: barChartsValueClientSegmentation,
                categories: categoriesClientSegmentation,
                dataForYAxis: dataForYAxisClientProduct,
                isXAxisVisible: false,
                height: '300px',
                chartType: 'bar',
                labelValue: 'Client Segmentation',
                showLabelIcon: true,
                tooltipEndLabelText: 'percent',
                updateDate: clientSegmentationGraphLastUpdate,
                updateName: clientSegmentationGraphUpdatedName,
            },

            {
                percentInputData: percentInputDataAUM,
                barChartsValue: barChartsValueAum,
                categories: categoriesAUM,
                dataForYAxis: dataForYAxisAUM as number[],
                isXAxisVisible: true,
                height: '350px',
                chartType: 'area',
                labelValue: 'AUM last 5 years',
                showLabelIcon: false,
                tooltipEndLabelText: 'Millions €',
                updateDate: aumLastFiveGraphLastUpdate,
                updateName: aumLastFiveGraphUpdatedName,
            },
        ],
        [
            percentInputDataProductSegmentation,
            barChartsValueProductSegmentation,
            categoriesProductSegmentation,
            dataForYAxisClientProduct,
            selectData,
            percentInputDataClientSegmentation,
            barChartsValueClientSegmentation,
            categoriesClientSegmentation,
            percentInputDataAUM,
            barChartsValueAum,
            categoriesAUM,
            dataForYAxisAUM,
            productSegmentationGraphLastUpdate,
            productSegmentationGraphUpdatedName,
            clientSegmentationGraphLastUpdate,
            clientSegmentationGraphUpdatedName,
            aumLastFiveGraphLastUpdate,
            aumLastFiveGraphUpdatedName,
        ]
    );

    return (
        <Grid container flexDirection={'column'}>
            {sections.map(
                (
                    {
                        percentInputData,
                        barChartsValue,
                        categories,
                        dataForYAxis,
                        isXAxisVisible,
                        height,
                        chartType,
                        labelValue,
                        showLabelIcon,
                        tooltipEndLabelText,
                        updateDate,
                        updateName,
                    },
                    index
                ) => (
                    <Grid container gap={2} key={labelValue}>
                        <Grid item>
                            <Typography variant='h6'>{labelValue}</Typography>
                        </Grid>
                        <Grid container gap={3}>
                            <>
                                <Grid container item>
                                    <Grid
                                        gap={1}
                                        container
                                        item
                                        flexDirection='column'
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                sm: '100%',
                                                md: '100%',
                                                lg: '50%',
                                                xl: '50%',
                                            },
                                        }}
                                    >
                                        {percentInputData.map(
                                            ({
                                                control,
                                                name,
                                                labelName,
                                                disabledInput,
                                            }) => (
                                                <TextInput
                                                    key={name}
                                                    control={control}
                                                    name={name}
                                                    labelName={labelName}
                                                    inputType='number'
                                                    disabledInput={
                                                        disabledInput as string
                                                    }
                                                />
                                            )
                                        )}
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                sm: '100%',
                                                md: '100%',
                                                lg: '50%',
                                                xl: '50%',
                                            },
                                        }}
                                    >
                                        <HighChartsComponent
                                            barChartsValue={barChartsValue}
                                            categories={categories}
                                            dataForYAxis={dataForYAxis}
                                            isXAxisVisible={isXAxisVisible}
                                            height={height}
                                            chartType={
                                                chartType as 'bar' | 'area'
                                            }
                                            showLabelIcon={showLabelIcon}
                                            tooltipEndLabelText={
                                                tooltipEndLabelText
                                            }
                                        />
                                    </Grid>
                                    <Grid sx={{ marginTop: '10px' }}>
                                        <FooterNote
                                            name={updateName}
                                            date={updateDate}
                                        />
                                    </Grid>
                                    {index !== sections.length - 1 && (
                                        <Divider
                                            sx={{
                                                my: 2,
                                                minWidth:
                                                    '-webkit-fill-available',
                                            }}
                                        />
                                    )}
                                </Grid>
                            </>
                        </Grid>
                    </Grid>
                )
            )}
        </Grid>
    );
}

export default Segmentation;
